import { Route } from 'src/types'
import {
  Accessibility,
  CookiePolicy,
  DataEntry,
  Error404,
  Landing,
  PaymentKo,
  PaymentOk,
} from 'src/ui/pages'
import { ClaimReport } from 'src/ui/pages/claim-report/claim-report'
import {
  ClaimIsOpen,
  ClaimOpening,
  ClaimOpenPending,
  ClaimOpenSummary,
  ClaimReportError,
  ClaimReportOpen,
  ClaimReportRcInjury,
  ClaimReportRcInjuryDateHour,
  ClaimReportRcInjuryDocument,
  ClaimReportRcInjuryInsuredPerson,
  ClaimReportRcInjuryPersonalData,
  ClaimReportRcInjuryRefound,
  ClaimReportRcInjuryReportDamageStep1,
  ClaimReportRcInjuryReportDamageStep2,
  ClaimReportRcInjuryReportInjuryStep1,
  ClaimReportRcInjuryReportInjuryStep2,
  ClaimReportRcInjuryRequestReason,
  ClaimReportType,
  ClaimReportWarning,
  ClaimReportWeather,
  ClaimReportWeatherDeclaration,
  ClaimReportWeatherDocument,
  ClaimReportWeatherInformation,
  ClaimReportWeatherInsuredPerson,
} from 'src/ui/pages/claim-report/sections'
import { Faqs } from 'src/ui/pages/faqs/faqs'

export const Paths = {
  CookiePolicy: '/cookie-policy',
  DataEntry1: '/inserimento-dati-step-1',
  DataEntry2: '/inserimento-dati-step-2',
  DataEntry3: '/inserimento-dati-step-3',
  PaymentOk: '/esito-pagamento-ok',
  PaymentKo: '/esito-pagamento-ko',
  Accessibility: '/accessibilita',
  ClaimReport: '/denuncia-sinistro',
  ClaimReportType: '/natura-del-sinistro',
  ClaimReportWeather: '/meteo-avverso',
  ClaimReportWeatherDeclaration: '/meteo-avverso/dichiarazione',
  ClaimReportWeatherInsuredPerson: '/meteo-avverso/persona-assicurata',
  ClaimReportWeatherInformation: '/meteo-avverso/informazioni-sul-sinistro',
  ClaimReportWeatherDocument: '/meteo-avverso/carica-documento',
  ClaimReportRcInjury: '/sinistro-rc-infortunio',
  ClaimReportRcInjuryDocument: '/sinistro-rc-infortunio/carica-documento',
  ClaimReportRcInjuryDateHour: '/sinistro-rc-infortunio/data-ora',
  ClaimReportRcInjuryInsuredPerson: '/sinistro-rc-infortunio/persona-assicurata',
  ClaimReportRcInjuryPersonalData: '/sinistro-rc-infortunio/dati-anagrafici',
  ClaimReportRcInjuryRequestReason: '/sinistro-rc-infortunio/di-cosa-hai-bisogno',
  ClaimReportRcInjuryReportInjuryStep1:
    '/sinistro-rc-infortunio/denunciare-una-lesione-propria-step1',
  ClaimReportRcInjuryReportInjuryStep2:
    '/sinistro-rc-infortunio/denunciare-una-lesione-propria-step2',
  ClaimReportRcInjuryReportDamageStep1:
    '/sinistro-rc-infortunio/denunciare-un-danno-causato-a-terzi-step-1',
  ClaimReportRcInjuryReportDamageStep2:
    '/sinistro-rc-infortunio/denunciare-un-danno-causato-a-terzi-step-2',
  ClaimReportRcInjuryRefound: '/sinistro-rc-infortunio/chiedere-il-rimborso-spese',
  ClaimReportOpen: '/conferma-apertura-sinistro',
  ClaimReportError: '/denuncia-sinistro-errore',
  ClaimReportWarning: '/denuncia-sinistro-avviso',
  ClaimOpenSummary: '/denuncia-sinistro-riepilogo',
  ClaimOpenPending: '/controlla-denuncia-sinistro',
  ClaimOpening: '/apertura-sinistro',
  ClaimIsOpen: '/sinistro-aperto',
  Faqs: '/faqs',
  LandingId: '/:id',
  Landing: '/',
  Error404: '*',
}

export const NotLandingPaths = [
  Paths.CookiePolicy,
  Paths.DataEntry1,
  Paths.DataEntry2,
  Paths.DataEntry3,
  Paths.PaymentOk,
  Paths.PaymentKo,
  Paths.Accessibility,
  Paths.ClaimReport,
  Paths.ClaimReportType,
  Paths.ClaimReportWeather,
  Paths.ClaimReportWeatherDeclaration,
  Paths.ClaimReportWeatherInsuredPerson,
  Paths.ClaimReportWeatherInformation,
  Paths.ClaimReportWeatherDocument,
  Paths.ClaimReportRcInjury,
  Paths.ClaimReportRcInjuryDocument,
  Paths.ClaimReportRcInjuryDateHour,
  Paths.ClaimReportRcInjuryInsuredPerson,
  Paths.ClaimReportRcInjuryPersonalData,
  Paths.ClaimReportRcInjuryRequestReason,
  Paths.ClaimReportRcInjuryReportInjuryStep1,
  Paths.ClaimReportRcInjuryReportInjuryStep2,
  Paths.ClaimReportRcInjuryReportDamageStep1,
  Paths.ClaimReportRcInjuryReportDamageStep2,
  Paths.ClaimReportRcInjuryRefound,
  Paths.ClaimReportOpen,
  Paths.ClaimReportError,
  Paths.ClaimReportWarning,
  Paths.ClaimOpenSummary,
  Paths.ClaimOpenPending,
  Paths.ClaimOpening,
  Paths.ClaimIsOpen,
  Paths.Faqs,
  Paths.Error404,
]

export const IsClaimsPage = [
  Paths.ClaimReport,
  Paths.ClaimReportType,
  Paths.ClaimReportWeather,
  Paths.ClaimReportWeatherDeclaration,
  Paths.ClaimReportWeatherInsuredPerson,
  Paths.ClaimReportWeatherInformation,
  Paths.ClaimReportWeatherDocument,
  Paths.ClaimReportRcInjury,
  Paths.ClaimReportRcInjuryDocument,
  Paths.ClaimReportRcInjuryDateHour,
  Paths.ClaimReportRcInjuryInsuredPerson,
  Paths.ClaimReportRcInjuryPersonalData,
  Paths.ClaimReportRcInjuryRequestReason,
  Paths.ClaimReportRcInjuryReportInjuryStep1,
  Paths.ClaimReportRcInjuryReportInjuryStep2,
  Paths.ClaimReportRcInjuryReportDamageStep1,
  Paths.ClaimReportRcInjuryReportDamageStep2,
  Paths.ClaimReportRcInjuryRefound,
  Paths.ClaimReportOpen,
  Paths.ClaimReportError,
  Paths.ClaimReportWarning,
  Paths.ClaimOpenSummary,
  Paths.ClaimOpenPending,
  Paths.ClaimOpening,
  Paths.ClaimIsOpen,
]

export const Routes: Record<keyof typeof Paths, Route> = {
  CookiePolicy: {
    path: Paths.CookiePolicy,
    element: <CookiePolicy />,
  },
  DataEntry1: {
    path: Paths.DataEntry1,
    element: <DataEntry step={0} />,
    private: true,
  },
  DataEntry2: {
    path: Paths.DataEntry2,
    element: <DataEntry step={1} />,
    private: true,
  },
  DataEntry3: {
    path: Paths.DataEntry3,
    element: <DataEntry step={2} />,
    private: true,
  },
  PaymentOk: {
    path: Paths.PaymentOk,
    element: <PaymentOk />,
    private: true,
  },
  PaymentKo: {
    path: Paths.PaymentKo,
    element: <PaymentKo />,
    private: true,
  },
  Faqs: {
    path: Paths.Faqs,
    element: <Faqs />,
    private: true,
  },
  Accessibility: {
    path: Paths.Accessibility,
    element: <Accessibility />,
    private: true,
  },
  LandingId: {
    path: '/:id',
    element: <Landing />,
    private: true,
  },
  Landing: {
    path: Paths.Landing,
    element: <Landing />,
    private: true,
  },
  ClaimReport: {
    path: Paths.ClaimReport,
    element: <ClaimReport />,
    private: true,
  },
  ClaimReportType: {
    path: Paths.ClaimReportType,
    element: <ClaimReportType />,
    private: true,
  },
  ClaimReportWeather: {
    path: Paths.ClaimReportWeather,
    element: <ClaimReportWeather />,
    private: true,
  },
  ClaimReportWeatherDeclaration: {
    path: Paths.ClaimReportWeatherDeclaration,
    element: <ClaimReportWeatherDeclaration />,
    private: true,
  },
  ClaimReportWeatherInsuredPerson: {
    path: Paths.ClaimReportWeatherInsuredPerson,
    element: <ClaimReportWeatherInsuredPerson />,
    private: true,
  },
  ClaimReportWeatherInformation: {
    path: Paths.ClaimReportWeatherInformation,
    element: <ClaimReportWeatherInformation />,
    private: true,
  },
  ClaimReportWeatherDocument: {
    path: Paths.ClaimReportWeatherDocument,
    element: <ClaimReportWeatherDocument />,
    private: true,
  },
  ClaimReportRcInjury: {
    path: Paths.ClaimReportRcInjury,
    element: <ClaimReportRcInjury />,
    private: true,
  },
  ClaimReportRcInjuryDocument: {
    path: Paths.ClaimReportRcInjuryDocument,
    element: <ClaimReportRcInjuryDocument />,
    private: true,
  },
  ClaimReportRcInjuryDateHour: {
    path: Paths.ClaimReportRcInjuryDateHour,
    element: <ClaimReportRcInjuryDateHour />,
    private: true,
  },
  ClaimReportRcInjuryInsuredPerson: {
    path: Paths.ClaimReportRcInjuryInsuredPerson,
    element: <ClaimReportRcInjuryInsuredPerson />,
    private: true,
  },
  ClaimReportRcInjuryPersonalData: {
    path: Paths.ClaimReportRcInjuryPersonalData,
    element: <ClaimReportRcInjuryPersonalData />,
    private: true,
  },
  ClaimReportRcInjuryRequestReason: {
    path: Paths.ClaimReportRcInjuryRequestReason,
    element: <ClaimReportRcInjuryRequestReason />,
    private: true,
  },
  ClaimReportRcInjuryReportInjuryStep1: {
    path: Paths.ClaimReportRcInjuryReportInjuryStep1,
    element: <ClaimReportRcInjuryReportInjuryStep1 />,
    private: true,
  },
  ClaimReportRcInjuryReportInjuryStep2: {
    path: Paths.ClaimReportRcInjuryReportInjuryStep2,
    element: <ClaimReportRcInjuryReportInjuryStep2 />,
    private: true,
  },
  ClaimReportRcInjuryReportDamageStep1: {
    path: Paths.ClaimReportRcInjuryReportDamageStep1,
    element: <ClaimReportRcInjuryReportDamageStep1 />,
    private: true,
  },
  ClaimReportRcInjuryReportDamageStep2: {
    path: Paths.ClaimReportRcInjuryReportDamageStep2,
    element: <ClaimReportRcInjuryReportDamageStep2 />,
    private: true,
  },
  ClaimReportRcInjuryRefound: {
    path: Paths.ClaimReportRcInjuryRefound,
    element: <ClaimReportRcInjuryRefound />,
    private: true,
  },
  ClaimReportOpen: {
    path: Paths.ClaimReportOpen,
    element: <ClaimReportOpen />,
    private: true,
  },
  ClaimReportError: {
    path: Paths.ClaimReportError,
    element: <ClaimReportError />,
    private: true,
  },
  ClaimReportWarning: {
    path: Paths.ClaimReportWarning,
    element: <ClaimReportWarning />,
    private: true,
  },
  ClaimOpenSummary: {
    path: Paths.ClaimOpenSummary,
    element: <ClaimOpenSummary />,
    private: true,
  },
  ClaimOpenPending: {
    path: Paths.ClaimOpenPending,
    element: <ClaimOpenPending />,
    private: true,
  },
  ClaimOpening: {
    path: Paths.ClaimOpening,
    element: <ClaimOpening />,
    private: true,
  },
  ClaimIsOpen: {
    path: Paths.ClaimIsOpen,
    element: <ClaimIsOpen />,
    private: true,
  },
  Error404: {
    path: Paths.Error404,
    element: <Error404 />,
  },
}
