/* eslint-disable no-negated-condition */
/* eslint-disable complexity */
import './header.scss'

import React from 'react'
import { Image } from 'react-bootstrap'
import { useLocation } from 'react-router'
import { ItasMutua, ItasNowFaq, ItasNowWhite, RightArrowIcon } from 'src/assets'
import { IsClaimsPage, NotLandingPaths, Paths } from 'src/navigation'
import { typedMemo } from 'src/types'
import { Button } from 'src/ui/widgets'
import { concatClasses } from 'src/utils'

import { useHeader } from './header.hook'

export const Header = typedMemo(() => {
  const { t, radioLanguage, setIT, setDE, setEN, goToDataEntry } = useHeader()

  const { pathname } = useLocation()

  const isNotLanding = NotLandingPaths.includes(pathname) && pathname !== Paths.Faqs

  const isFaq = NotLandingPaths.includes(pathname) && pathname === Paths.Faqs

  const showLanguage = IsClaimsPage.includes(pathname)

  const Logo = (
    <Image
      className={isNotLanding ? '' : 'header-logo'}
      src={isNotLanding ? ItasMutua : isFaq ? ItasNowFaq : ItasNowWhite}
      width="auto"
      height={40}
    />
  )

  const LanguageButtons = (
    <div className="header-radios align-items-center">
      <Button
        text={t('language.it')}
        classes={concatClasses(
          isNotLanding
            ? 'header-language-radio'
            : isFaq
            ? 'header-language-radio-faq'
            : 'header-language-radio-landing',
          radioLanguage === 'it' && isNotLanding
            ? 'c-862633 text-decoration-underline'
            : isFaq && radioLanguage === 'it'
            ? 'c-000000 text-decoration-underline'
            : !isNotLanding && radioLanguage === 'it'
            ? 'c-ffffff text-decoration-underline'
            : isFaq
            ? 'c-7f7f7f'
            : !isNotLanding
            ? 'c-ffffff80'
            : 'c-862633 opacity-50'
        )}
        classesText="text-uppercase font-14-ag-400"
        onPress={setIT}
      />

      {!showLanguage && (
        <>
          <div className="header-radios align-items-center">
            <div
              className={`header-separator pb-1 ${
                isNotLanding ? 'c-862633' : isFaq ? 'c-000000' : 'c-ffffff'
              }`}
            >
              |
            </div>

            <Button
              text={t('language.en')}
              classes={concatClasses(
                isNotLanding
                  ? 'header-language-radio'
                  : isFaq
                  ? 'header-language-radio-faq'
                  : 'header-language-radio-landing',
                radioLanguage === 'en' && isNotLanding
                  ? 'c-862633 text-decoration-underline'
                  : isFaq && radioLanguage === 'en'
                  ? 'c-000000 text-decoration-underline'
                  : !isNotLanding && radioLanguage === 'en'
                  ? 'c-ffffff text-decoration-underline'
                  : isFaq
                  ? 'c-7f7f7f'
                  : !isNotLanding
                  ? 'c-ffffff80'
                  : 'c-862633 opacity-50'
              )}
              classesText="text-uppercase font-14-ag-400"
              onPress={setEN}
            />

            <div
              className={`header-separator pb-1 ${
                isNotLanding ? 'c-862633' : isFaq ? 'c-000000' : 'c-ffffff'
              }`}
            >
              |
            </div>

            <Button
              text={t('language.de')}
              classes={concatClasses(
                isNotLanding
                  ? 'header-language-radio'
                  : isFaq
                  ? 'header-language-radio-faq'
                  : 'header-language-radio-landing',
                radioLanguage === 'de' && isNotLanding
                  ? 'c-862633 text-decoration-underline'
                  : isFaq && radioLanguage === 'de'
                  ? 'c-000000 text-decoration-underline'
                  : !isNotLanding && radioLanguage === 'de'
                  ? 'c-ffffff text-decoration-underline'
                  : isFaq
                  ? 'c-7f7f7f'
                  : !isNotLanding
                  ? 'c-ffffff80'
                  : 'c-862633 opacity-50'
              )}
              classesText="text-uppercase font-14-ag-400"
              onPress={setDE}
            />
          </div>
          {!isNotLanding && (
            <Button
              text={t('commons.buy_now_button')}
              iconRight={RightArrowIcon}
              classes={concatClasses(
                isFaq ? 'bg-2727ab' : '',
                'header-button p-2 ps-3 pe-3 d-flex justify-content-center'
              )}
              classesText={concatClasses(isFaq ? 'c-ffffff' : '', 'font-14-ag-500')}
              classesIconRight={concatClasses(isFaq ? 'ms-3 filter-ffffff' : 'ms-3')}
              onPress={goToDataEntry}
            />
          )}
        </>
      )}
    </div>
  )

  return (
    <div>
      <header className={isNotLanding ? 'header-navbar' : 'header-navbar-landing'}>
        {Logo}
        {LanguageButtons}
      </header>
    </div>
  )
})
