/* eslint-disable complexity */
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { DEFAULT_AGENCY_CODE, PERIOD_MAPPER } from 'src/config'
import { upperLanguage } from 'src/i18n'
import { Paths } from 'src/navigation'
import {
  toEvaluatePremiumRequest,
  usePostEvaluatePremium,
  usePostPayment,
} from 'src/networking/terminals'
import {
  atomCurrentStep,
  atomEvaluatePremium,
  atomFormikMainInsured,
  atomGoogleEcommerce,
  atomProduct,
  atomQuote,
  selectorAgency,
  selectorFactorLimits,
  selectorFactors,
  selectorPeriods,
} from 'src/storage'
import {
  AxeptaWindow,
  FormikMainInsured,
  GoogleWindow,
  InputSelectOption,
  MinMaxContractor,
  seasonal_t,
} from 'src/types'
import { submitCaptcha } from 'src/ui/widgets'
import { useBoolean } from 'src/utils'

import { useGuarantees } from './handlers/guarantees.hook'
import { usePrice } from './handlers/price.hook'
import { useScrollToError } from './handlers/scroll-to-error.hook'
import { useValidationSchemas } from './handlers/validation-schemas.hook'

export const useDataEntry = (step: number) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const [formikMainInsured, saveFormikMainInsured] = useRecoilState(atomFormikMainInsured)

  const resetQuote = useResetRecoilState(atomQuote)
  const resetEvaluatePremium = useResetRecoilState(atomEvaluatePremium)
  const factors = useRecoilValue(selectorFactors)
  const factorsLimits = useRecoilValue(selectorFactorLimits)
  const agency = useRecoilValue(selectorAgency)
  const periods = useRecoilValue(selectorPeriods)
  const [currentStep, saveCurrentStep] = useRecoilState(atomCurrentStep)
  const saveGoogleEcommerce = useSetRecoilState(atomGoogleEcommerce)
  const [updatePremium, setUpdatePremium] = useState(false)
  const [discountError, setDiscountError] = useState('')
  const [scrollEvaluated, setScrollEvaluated] = useState(true)
  const [quoting, turnOnQuoting, turnOffQuoting] = useBoolean()
  const product = useRecoilValue(atomProduct)
  const { crossGate: postEvaluatePremium } = usePostEvaluatePremium()

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const [quote] = useRecoilState(atomQuote)
  const [evaluatedPremium, saveEvaluatePremium] = useRecoilState(atomEvaluatePremium)
  const price = usePrice(discountError)
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const urlSearchParams = new URLSearchParams(useLocation().search)
  const seasonal = urlSearchParams.get('seasonal') === 'true'

  /**
   * useEffect
   */
  useEffect(() => {
    if (currentStep !== 0) {
      window?.scrollTo(0, 0)
    }
    if (evaluatedPremium && scrollEvaluated && currentStep === 1) {
      setScrollEvaluated(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluatedPremium, scrollEvaluated, currentStep])

  const trackPurchase = () => {
    const googleWindow: GoogleWindow = window
    googleWindow.dataLayer = googleWindow?.dataLayer || []
    googleWindow.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    const ecommerce = {
      value: +(quote?.quoteValue || 0),
      currency: 'EUR',
      items: [
        {
          item_category: formikMainInsured?.insured_days,
          item_category2: formikMainInsured?.coverage_type_radios === '1' ? 'Base' : 'Plus',
        },
      ],
    }
    saveGoogleEcommerce(ecommerce)
    googleWindow.dataLayer.push({ event: 'begin_checkout', ecommerce })
  }

  const proceedToPayment = (paymentId: string) => {
    const axeptaWindow: AxeptaWindow = window
    axeptaWindow?.callAxeptaClient?.()?.proceedToPayment?.(paymentId)
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const { crossGate: postPayment } = usePostPayment()

  const doPostPayment = () => {
    submitCaptcha().then((token) => {
      postPayment(
        {
          body: {
            token,
            agencyId: agency?.id,
            quoteId: quote?.quoteId,
            language: upperLanguage,
          },
        },
        {
          onSuccess: ({ paymentId }) => {
            if (paymentId) {
              trackPurchase()
              proceedToPayment(paymentId)
            }
          },
        }
      )
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (!isNaN(step)) {
      saveCurrentStep(step)
    }
  }, [step])

  const validationSchemas = useValidationSchemas()

  const formik = useFormik<FormikMainInsured>({
    initialValues: formikMainInsured,
    validationSchema: validationSchemas[currentStep],
    onSubmit: (values, { setTouched, setSubmitting }) => {
      setTouched({})
      saveFormikMainInsured(values)
      if (currentStep < 2) {
        saveCurrentStep((x) => Math.min(2, x + 1))
        if (currentStep + 1 === 1) {
          navigate({
            pathname: Paths.DataEntry2,
            search: `?base=${values?.coverage_type_radios === '1'}&seasonal=${
              values?.insured_days === 'id_seasonal'
            }&utm_source=${
              PERIOD_MAPPER[
                (
                  `${values?.coverage_type_radios === '1'}` +
                  `_${values?.insured_days === 'id_seasonal'}`
                )?.toString()
              ]
            }&utm_medium=home`,
          })
        } else if (currentStep + 1 === 2) {
          navigate({
            pathname: Paths.DataEntry3,
            search: `?base=${values?.coverage_type_radios === '1'}&seasonal=${
              values?.insured_days === 'id_seasonal'
            }&utm_source=${
              PERIOD_MAPPER[
                (
                  `${values?.coverage_type_radios === '1'}` +
                  `_${values?.insured_days === 'id_seasonal'}`
                )?.toString()
              ]
            }&utm_medium=home`,
          })
        }
        if (currentStep === 0) {
          evaluatePremium()
        }
        setSubmitting(false)
      } else {
        doPostPayment()
      }
    },
  })

  const { values, setValues } = formik

  const continueDisabled = currentStep === 1 && values.insured_list?.length < factorsLimits.min - 1

  useEffect(() => {
    if (currentStep === 0 && values?.insured_list && values?.insured_list?.length > 0) {
      const state = { ...values }
      state.insured_list = []
      setValues(state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, values?.insured_list, values?.insured_number, currentStep])

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  useGuarantees(formik)

  useEffect(() => {
    formik.setFieldValue('insured_days', seasonal ? 'id_seasonal' : 'id_daily')
  }, [])

  const { triggerFormikCheck } = useScrollToError(formik)

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const goToLanding = () => {
    const agencyCode = agency && agency.id !== DEFAULT_AGENCY_CODE ? agency.id : ''
    navigate(Paths.Landing + agencyCode)
  }

  const goBack = () => {
    resetQuote()
    resetEvaluatePremium()
    if (currentStep > 0) {
      setValues({ ...values, discount: '' })
      saveCurrentStep((x) => Math.max(0, x - 1))
      if (currentStep - 1 === 0) {
        navigate({
          pathname: Paths.DataEntry1,
          search: `?base=${values?.coverage_type_radios === '1'}&seasonal=${
            values?.insured_days === 'id_seasonal'
          }&utm_source=${
            PERIOD_MAPPER[
              (
                `${values?.coverage_type_radios === '1'}` +
                `_${values?.insured_days === 'id_seasonal'}`
              )?.toString()
            ]
          }&utm_medium=home`,
        })
        setScrollEvaluated(true)
      } else if (currentStep - 1 === 1) {
        navigate({
          pathname: Paths.DataEntry2,
          search: `?base=${values?.coverage_type_radios === '1'}&seasonal=${
            values?.insured_days === 'id_seasonal'
          }&utm_source=${
            PERIOD_MAPPER[
              (
                `${values?.coverage_type_radios === '1'}` +
                `_${values?.insured_days === 'id_seasonal'}`
              )?.toString()
            ]
          }&utm_medium=home`,
        })
      }
    } else {
      goToLanding()
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (!factors || !periods || !agency) {
      goToLanding()
    }
  }, [factors, periods, agency])

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const daysOptions: InputSelectOption[] = [
    { id: 'id_daily', value: t('data_entry_step_1.coverage_daily') },
    { id: 'id_period', value: t('data_entry_step_1.coverage_period') },
    { id: 'id_seasonal', value: t('data_entry_step_1.coverage_seasonal') },
  ]

  const dailyOptions: InputSelectOption[] = [
    {
      id: 'daily_1',
      value: '1',
    },
    {
      id: 'daily_2',
      value: '2',
    },
    {
      id: 'daily_3',
      value: '3',
    },
    {
      id: 'daily_4',
      value: '4',
    },
  ]

  const [minMaxContractor, setMinMaxContractor] = useState<MinMaxContractor>()
  const [, setSeasonal] = useState<seasonal_t>()

  useEffect(() => {
    if (factors) {
      setMinMaxContractor({
        max: factors['1']?.max || 1,
        min: factors['1']?.min || 1,
      })

      setSeasonal({
        seasonal_end_date: factors['2']?.maxDate,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factors])

  useEffect(() => {
    if (updatePremium) {
      doPostEvaluatePremium()
      setUpdatePremium(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updatePremium,
    currentStep,
    values?.coverage_type_radios,
    values?.insured_number,
    values?.insured_days,
    values?.insured_daily,
    values?.is_family,
  ])

  /////

  const seasonalBase = product?.configuration?.coverages?.['1']?.seasonalBasePremium
  const seasonalPlus = product?.configuration?.coverages?.['2']?.seasonalBasePremium
  const quoteValue = Number(quote?.quoteValue?.replace(',', '.'))

  const warning =
    values.insured_days === 'id_period' &&
    ((seasonalBase && values.coverage_type_radios === '1' && quoteValue >= seasonalBase) ||
      (seasonalPlus && values.coverage_type_radios === '2' && quoteValue >= seasonalPlus))

  const evaluatePremium = () => {
    setUpdatePremium(true)
  }

  useEffect(() => {
    if (values.discount === '') {
      setDiscountError('')
    }
  }, [values.discount])

  const doPostEvaluatePremium = () => {
    postEvaluatePremium(
      {
        body: toEvaluatePremiumRequest(values),
      },
      {
        onSuccess: saveEvaluatePremium,
        onPending: turnOnQuoting,
        onIdle: turnOffQuoting,
      }
    )
  }

  return {
    t,
    agency,
    daysOptions,
    dailyOptions,
    minMaxContractor,
    quote,
    currentStep,
    validationSchemas,
    formik,
    continueDisabled,
    discountError,
    price,
    updatePremium,
    quoting,
    warning,
    evaluatedPremium,
    setDiscountError,
    triggerFormikCheck,
    evaluatePremium,
    goBack,
  }
}
