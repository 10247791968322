import i18n from 'src/i18n'
import { regexBirthDate, regexDate } from 'src/regex'
import * as yup from 'yup'

export const yupNum = yup.number().typeError(i18n.t('commons.error_number'))
export const yupStr = yup.string()
export const yupBool = yup.bool()
export const yupArray = yup.array()
export const yupObject = yup.object()
export const yupEmail = yup.string().email(i18n.t('commons.invalid_email'))
export const yupDate = yupStr.matches(regexDate, i18n.t('commons.invalid_date'))
export const yupBirthDate = yupStr.matches(regexBirthDate, i18n.t('commons.invalid_date'))
export const ystr = yup.string()
export const ystr_required = ystr.required(i18n.t('commons.required'))
export const yupNumRequired = yupNum.required(i18n.t('commons.required'))
export const yupStrRequired = yupStr.required(i18n.t('commons.required'))
export const yupArrayRequired = yupArray.required(i18n.t('commons.required'))
export const yupObjectRequired = yupObject.required(i18n.t('commons.required'))
export const yupBoolRequired = yupBool
  .required(i18n.t('commons.required'))
  .oneOf([true], i18n.t('commons.required'))
export const yupEmailRequired = yupEmail.required(i18n.t('commons.email_required'))
export const yupDateRequired = yupDate.required(i18n.t('commons.required'))

const isRequired = (validationSchema?: yup.AnyObjectSchema, key = ''): boolean => {
  return validationSchema?.fields?.[key]?.exclusiveTests?.required
}

export const formatLabel = (validationSchema?: yup.AnyObjectSchema, id = '', label = '') => {
  return isRequired(validationSchema, id) ? `${label}* ` : `${label} `
}
