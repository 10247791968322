import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useSetRecoilState } from 'recoil'
import { Paths } from 'src/navigation'
import { atomCurrentStep } from 'src/storage'

export const usePaymentKo = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const saveCurrentStep = useSetRecoilState(atomCurrentStep)

  const goToDataEntry = () => {
    saveCurrentStep(2)
    navigate(Paths.DataEntry3)
  }

  return { t, goToDataEntry }
}
