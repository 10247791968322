import { atom } from 'recoil'
import { persistentAtom } from 'recoil-persistence/react'
import {
  ClaimQuote,
  EvaluatePremium,
  FormikMainInsured,
  GoogleEcommerce,
  Optional,
  Product,
  Quote,
  Sandwiches,
} from 'src/types'
import { ClaimData, FilesRefounds } from 'src/types/pages/claim.types'
import { PROGRESS_BAR_STEP } from 'src/ui/pages/claim-report/progress-bar-step'

// Start showcase: remove it when feel like a bomber 🚀🚀🚀
export const atomSide1 = atom<number>({
  key: 'side1',
  default: 1,
})

export const atomSide2 = atom<number>({
  key: 'side2',
  default: 2,
})

export const atomSandwiches = atom<Optional<Sandwiches>>({
  key: 'sandwiches',
  default: undefined,
})
// End showcase

export const atomFromApp = atom<boolean>({
  key: 'fromApp',
  default: false,
})

export const atomReloadCookie = persistentAtom<boolean>({
  key: 'reload_cookie',
  default: false,
})

export const atomCurrentStep = persistentAtom<number>({
  key: 'currentStep',
  default: 0,
})

export const atomProduct = persistentAtom<Optional<Product>>({
  key: 'product',
  default: undefined,
})

export const atomFormikMainInsured = persistentAtom<FormikMainInsured>({
  key: 'formikMainInsured',
  default: {
    coverage_type_radios: '1',
    coverage_start_date: '',
    coverage_end_date: '',
    convention: '',
    insured_name: '',
    insured_surname: '',
    insured_email: '',
    insured_email_confirm: '',
    insured_phone: {
      country: {
        name: '',
        dialCode: '',
        countryCode: '',
        format: '',
      },
      formattedPhone: '',
      value: '',
    },
    insured_cap: '',
    insured_nationality: 'IT',
    insured_fc_question: '',
    insured_birth: '',
    insured_birth_nation: '',
    insured_birth_place: '',
    insured_gender: '',
    insured_days: 'id_daily',
    insured_fc: '',
    consent_td: true,
    consent_privacy: false,
    consent_marketing: false,
    consent_profiling: false,
    consent_communication: false,
    insured_list: [],
    informative_vision: false,
    discount: '',
    insured_daily: '1',
    insured_number: '1',
    is_family: false,
  },
})

export const atomQuote = atom<Optional<Quote>>({
  key: 'quote',
  default: undefined,
})

export const atomEvaluatePremium = atom<Optional<EvaluatePremium>>({
  key: 'evaluatePremium',
  default: undefined,
})

export const atomClaimQuote = persistentAtom<Optional<ClaimQuote>>(
  {
    key: 'claimQuote',
    default: undefined,
  },
  {
    storageKey: 'claimQuote-SS',
    storage: window.sessionStorage,
  }
)

export const atomProgressBar = persistentAtom<Optional<number>>(
  {
    key: 'progressBar',
    default: PROGRESS_BAR_STEP.claim_report_summary,
  },
  {
    storageKey: 'progressBar-SS',
    storage: window.sessionStorage,
  }
)

export const atomClaimData = persistentAtom<Optional<ClaimData>>(
  {
    key: 'claimData',
    default: {
      selectedType: '',
      other: {
        file: undefined,
        date: '',
        hour: '',
        insuredType: '',
        personalData: undefined,
        requestType: '',
        request: {},
      },
      weather: {},
    },
  },
  {
    storageKey: 'claimData-SS',
    storage: window.sessionStorage,
  }
)

export const atomClaimId = persistentAtom<Optional<string>>(
  {
    key: 'claimId',
    default: '',
  },
  {
    storageKey: 'claimId-SS',
    storage: window.sessionStorage,
  }
)

export const atomClaimDataFiles = atom<Optional<FilesRefounds>>({
  key: 'claimDataFiles',
  default: {
    files: {},
  },
})

export const atomClaimBackPath = persistentAtom<Optional<string>>(
  {
    key: 'claimBackPath',
    default: '',
  },
  {
    storageKey: 'claimBackPath-SS',
    storage: window.sessionStorage,
  }
)

export const atomGoogleEcommerce = persistentAtom<Optional<GoogleEcommerce>>({
  key: 'googleEcommerce',
  default: undefined,
})
