import './header.scss'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import i18n, { LowerLanguage, lowerLanguage } from 'src/i18n'
import { Paths } from 'src/navigation'

export const useHeader = () => {
  const { t } = useTranslation()

  const [radioLanguage, setRadioLanguage] = useState(lowerLanguage)

  const navigate = useNavigate()

  const onChange = (language: LowerLanguage) => {
    setRadioLanguage(language)
    i18n.changeLanguage(language)
    setTimeout(() => window.location.reload())
  }

  const setIT = () => onChange('it')
  const setDE = () => onChange('de')
  const setEN = () => onChange('en')

  /**
   * Functions
   */
  const goToDataEntry = () => {
    navigate({
      pathname: Paths.DataEntry1,
      search: `?${new URLSearchParams({
        base: 'true',
        seasonal: 'false',
      }).toString()}`,
    })
  }

  return { t, radioLanguage, setIT, setDE, setEN, goToDataEntry }
}
