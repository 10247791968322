import './section8.scss'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { Paths } from 'src/navigation'
import { selectorFaqs } from 'src/storage'

export const useSection8 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const numberOfFaqToShow = 4

  const faqs = useRecoilValue(selectorFaqs)

  const goToFaqs = () => {
    navigate(Paths.Faqs)
  }

  return {
    t,
    faqs,
    numberOfFaqToShow,
    goToFaqs,
  }
}
