/* eslint-disable complexity */
import 'react-phone-input-2/lib/bootstrap.css'

import { FormikErrors, FormikProvider } from 'formik'
import { ChangeEvent } from 'react'
import PhoneInput from 'react-phone-input-2'
import { ScrollTopRoot } from 'src/roots'
import { DataEntryStepProps, FormikOtherInsured, typedMemo } from 'src/types'
import { Accordion, Button, InputCheckbox, InputDate, InputRadio, InputText } from 'src/ui/widgets'

import { useDataEntryStep2 } from './data-entry-step2.hook'

export const DataEntryStep2 = typedMemo(({ validationSchema, formik }: DataEntryStepProps) => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } =
    formik

  const {
    t,
    lowerLanguage,
    isMobile,
    nationalityOptions,
    fcQuestionOptions,
    genderOptions,
    periodOptions,
    closePickerTrigger,
    coverageStartDateDisabledFrom,
    coverageStartDateDisabledTo,
    coverageEndDateDisabledFrom,
    coverageEndDateDisabledTo,
    currentDate,
    openInformativePersonalDataLink,
    onChangePhone,
  } = useDataEntryStep2(formik)

  const PeriodTypeDesktop = (
    <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3 d-none d-md-block">
      <InputRadio
        name="insured_days"
        handleChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (event?.target?.value === 'id_period') {
            setFieldValue('coverage_end_date', '')
          }
          handleChange(event)
        }}
        handleBlur={handleBlur}
        options={periodOptions}
        validationSchema={validationSchema}
        touched={touched.insured_days}
        value={values.insured_days}
        error={errors.insured_days}
        label={t('data_entry_step_1.coverage_subtitle')}
        classes="m-0 p-0 mb-3"
        classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
      />
    </div>
  )

  const PeriodTypeMobile = (
    <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3 d-block d-md-none">
      <InputRadio
        name="insured_days"
        handleChange={handleChange}
        handleBlur={handleBlur}
        options={periodOptions}
        validationSchema={validationSchema}
        touched={touched.insured_days}
        value={values.insured_days}
        error={errors.insured_days}
        label={t('data_entry_step_1.coverage_subtitle')}
        classes="m-0 p-0 mb-3"
        classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
        vertically
      />
    </div>
  )

  const PeriodTypeDates = (
    <div className="col-sm-12 col-md-12 p-0 px-md-3 pl-md-3 pr-md-3 d-block d-md-flex">
      <div className="col-sm-12 col-md-6 p-0 pe-md-3 m-0">
        <InputDate
          name="coverage_start_date"
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          validationSchema={validationSchema}
          touched={touched.coverage_start_date}
          value={values.coverage_start_date}
          error={errors.coverage_start_date}
          warning={currentDate ? t('data_entry_step_2.alert_close_ski_resorts') : ''}
          label={t('data_entry_step_1.coverage_start_date')}
          placeholder={t('data_entry_step_1.coverage_start_date_placeholder')}
          disabled={values.insured_days === 'id_seasonal'}
          disabledFrom={coverageStartDateDisabledFrom}
          disabledTo={coverageStartDateDisabledTo}
          classes="m-0 p-0"
          closePickerTrigger={closePickerTrigger}
        />
      </div>

      <div className="col-sm-12 col-md-6 p-0 ps-md-3 m-0">
        <InputDate
          name="coverage_end_date"
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          validationSchema={validationSchema}
          touched={touched.coverage_end_date}
          value={values.coverage_end_date}
          error={errors.coverage_end_date}
          label={t('data_entry_step_1.coverage_end_date')}
          placeholder={t('data_entry_step_1.coverage_end_date_placeholder')}
          disabled={values.insured_days !== 'id_period'}
          disabledFrom={coverageEndDateDisabledFrom}
          disabledTo={coverageEndDateDisabledTo}
          classes="m-0 p-0"
          closePickerTrigger={closePickerTrigger}
        />
      </div>
    </div>
  )

  const CoverageData = (
    <div className="border border-1 rounded-3 pt-3 ps-3 pe-3 ms-md-3 me-md-3 bg-ffffff pb-3">
      {PeriodTypeDesktop}
      {PeriodTypeMobile}
      {PeriodTypeDates}
    </div>
  )

  const FirstInsured = (
    <div className="border border-1 rounded-3 ps-3 pe-3 ms-md-3 me-md-3 bg-ffffff pb-3">
      <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 mb-2 mb-md-1 font-15-semibold mt-3">
        {t('data_entry_step_2.insured_subtitle')} 1
      </div>
      <div className="d-block d-md-flex">
        <div
          className={`${
            !touched.insured_name || !errors.insured_name ? 'pb-3' : ''
          }  col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3`}
        >
          <InputText
            name="insured_name"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.insured_name}
            value={values.insured_name}
            error={touched.insured_name && errors.insured_name ? errors.insured_name : ''}
            label={t('data_entry_step_2.insured_name')}
            placeholder={t('data_entry_step_2.insured_name_placeholder')}
          />
        </div>
        <div
          className={`${
            !touched.insured_surname || !errors.insured_surname ? 'pb-3' : ''
          }  col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3`}
        >
          <InputText
            name="insured_surname"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.insured_surname}
            value={values.insured_surname}
            error={touched.insured_surname && errors.insured_surname ? errors.insured_surname : ''}
            label={t('data_entry_step_2.insured_surname')}
            placeholder={t('data_entry_step_2.insured_surname_placeholder')}
          />
        </div>
      </div>

      <div className="d-block d-md-flex">
        <div
          className={`${
            !touched.insured_email || !errors.insured_email ? 'pb-3' : ''
          }  col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3`}
        >
          <InputText
            name="insured_email"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.insured_email}
            value={values.insured_email}
            error={touched.insured_email && errors.insured_email ? errors.insured_email : ''}
            label={t('data_entry_step_2.insured_email')}
            placeholder={t('data_entry_step_2.insured_email_placeholder')}
          />
        </div>
        <div
          className={`${
            !touched.insured_email_confirm || !errors.insured_email_confirm ? 'pb-3' : ''
          }  col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3`}
        >
          <InputText
            name="insured_email_confirm"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.insured_email_confirm}
            error={
              touched.insured_email_confirm && errors.insured_email_confirm
                ? errors.insured_email_confirm
                : ''
            }
            value={values.insured_email_confirm}
            label={t('data_entry_step_2.insured_email_confirm')}
            placeholder={t('data_entry_step_2.insured_email_confirm_placeholder')}
            onPaste={(event) => {
              event.preventDefault()
              return false
            }}
            onCopy={(event) => {
              event.preventDefault()
              return false
            }}
          />
        </div>
      </div>

      <div className="d-block d-md-flex">
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <label htmlFor="input-insured_phone" className="input-text--label d-block">
            {t('data_entry_step_2.insured_phone')}
          </label>
          <div
            className={errors.insured_phone?.formattedPhone ? 'phone-style-error' : 'phone-style'}
          >
            <PhoneInput
              country={lowerLanguage === 'it' ? 'it' : lowerLanguage === 'en' ? 'gb' : 'de'}
              placeholder={t('data_entry_step_2.insured_phone_placeholder')}
              value={values.insured_phone?.formattedPhone}
              onChange={onChangePhone}
            />
          </div>
          {errors.insured_phone?.formattedPhone && (
            <div className="font-14 c-862633">{errors.insured_phone?.formattedPhone}</div>
          )}
        </div>

        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <InputText
            name="insured_cap"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.insured_cap}
            value={values.insured_cap}
            error={errors.insured_cap}
            label={t('data_entry_step_2.insured_cap')}
            placeholder={t('data_entry_step_2.insured_cap_placeholder')}
          />
        </div>
      </div>

      <div className="d-block d-md-flex">
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <InputRadio
            name="insured_nationality"
            handleChange={handleChange}
            handleBlur={handleBlur}
            options={nationalityOptions}
            validationSchema={validationSchema}
            touched={touched.insured_nationality}
            value={values.insured_nationality}
            error={errors.insured_nationality}
            label={t('data_entry_step_2.insured_nationality')}
            classes="m-0 p-0"
            classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
          />
        </div>
      </div>

      <div className="d-block d-md-flex">
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
          <InputRadio
            name="insured_fc_question"
            handleChange={handleChange}
            handleBlur={handleBlur}
            options={fcQuestionOptions}
            validationSchema={validationSchema}
            touched={touched.insured_fc_question}
            value={values.insured_fc_question}
            error={
              errors.insured_fc_question && touched.insured_fc_question
                ? errors.insured_fc_question
                : ''
            }
            label={t('data_entry_step_2.insured_fc_question')}
            classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
            classes="m-0 p-0 mb-3"
          />
        </div>
      </div>

      {values.insured_fc_question === 'yes_id' && (
        <div className="d-block d-md-flex">
          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputText
              name="insured_fc"
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={validationSchema}
              touched={touched.insured_fc}
              value={values.insured_fc?.toUpperCase()}
              error={errors.insured_fc}
              label={`${t('data_entry_step_2.insured_fc')}*`}
              placeholder={t('data_entry_step_2.insured_fc_placeholder')}
            />
          </div>
        </div>
      )}

      {values.insured_fc_question === 'no_id' && (
        <div>
          <div className="d-block d-md-flex">
            <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
              <InputDate
                name="insured_birth"
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                validationSchema={validationSchema}
                touched={touched.insured_birth}
                value={values.insured_birth}
                error={errors.insured_birth}
                label={`${t('data_entry_step_2.insured_birth')}*`}
                placeholder={t('data_entry_step_2.insured_birth_placeholder')}
                disabledTo={new Date()}
                classes="m-0 p-0"
              />
            </div>

            <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
              <InputText
                name="insured_birth_nation"
                handleChange={handleChange}
                handleBlur={handleBlur}
                validationSchema={validationSchema}
                touched={touched.insured_birth_nation}
                value={values.insured_birth_nation}
                error={errors.insured_birth_nation}
                label={`${t('data_entry_step_2.insured_birth_nation')}*`}
                placeholder={t('data_entry_step_2.insured_birth_nation_placeholder')}
              />
            </div>
          </div>

          <div className="d-block d-md-flex">
            <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
              <InputText
                name="insured_birth_place"
                handleChange={handleChange}
                handleBlur={handleBlur}
                validationSchema={validationSchema}
                touched={touched.insured_birth_place}
                value={values.insured_birth_place}
                error={errors.insured_birth_place}
                label={`${t('data_entry_step_2.insured_birth_place')}*`}
                placeholder={t('data_entry_step_2.insured_birth_place_placeholder')}
              />
            </div>

            <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
              <InputRadio
                name="insured_gender"
                handleChange={handleChange}
                handleBlur={handleBlur}
                options={genderOptions}
                validationSchema={validationSchema}
                touched={touched.insured_gender}
                value={values.insured_gender}
                error={errors.insured_gender}
                label={`${t('data_entry_step_2.insured_gender')}*`}
                classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
                classes="m-0 p-0 mb-3"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const OtherInsured = (index: number) => {
    const insuredTouched = touched.insured_list?.[index]
    const insuredValue = values.insured_list?.[index]
    const insuredError = errors.insured_list?.[index] as FormikErrors<FormikOtherInsured>

    return (
      <div key={index} className="border border-1 rounded-3 p-3 ms-md-3 me-md-3 bg-ffffff mt-4">
        <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 mb-2 mb-md-1 font-15-semibold">
          {t('data_entry_step_2.insured_subtitle')} {index + 2}
        </div>
        <div className="d-block d-md-flex">
          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputText
              name={`insured_list.${index}.name`}
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={validationSchema?.fields?.insured_list?.innerType}
              touched={insuredTouched?.name}
              error={insuredError?.name}
              value={insuredValue?.name === undefined ? '' : insuredValue?.name}
              label={`${t('data_entry_step_2.insured_name')}*`}
              placeholder={t('data_entry_step_2.insured_name_placeholder')}
            />
          </div>
          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputText
              name={`insured_list.${index}.surname`}
              handleChange={handleChange}
              handleBlur={handleBlur}
              validationSchema={validationSchema?.fields?.insured_list?.innerType}
              touched={insuredTouched?.surname}
              value={insuredValue?.surname === undefined ? '' : insuredValue?.surname}
              error={insuredError?.surname}
              label={`${t('data_entry_step_2.insured_surname')}*`}
              placeholder={t('data_entry_step_2.insured_surname_placeholder')}
            />
          </div>
        </div>

        <div className="d-block">
          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputRadio
              name={`insured_list.${index}.nationality`}
              handleChange={handleChange}
              handleBlur={handleBlur}
              options={nationalityOptions}
              validationSchema={validationSchema?.fields?.insured_list?.innerType}
              touched={insuredTouched?.nationality}
              value={insuredValue?.nationality}
              error={insuredError?.nationality}
              label={t('data_entry_step_2.insured_nationality')}
              classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
              classes="m-0 p-0"
            />
          </div>

          <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
            <InputRadio
              name={`insured_list.${index}.fc_question`}
              handleChange={handleChange}
              handleBlur={handleBlur}
              options={fcQuestionOptions}
              validationSchema={validationSchema?.fields?.insured_list?.innerType}
              touched={insuredTouched?.fc_question}
              value={insuredValue?.fc_question}
              error={
                insuredError?.fc_question && insuredTouched?.fc_question
                  ? insuredError?.fc_question
                  : ''
              }
              label={`${t('data_entry_step_2.insured_fc_question')}*`}
              classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
              classes="m-0 p-0 mb-3"
            />
          </div>

          {values.insured_list?.[index]?.fc_question === 'yes_id' && (
            <div className="d-block d-md-flex">
              <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                <InputText
                  name={`insured_list.${index}.fc`}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  validationSchema={validationSchema?.fields?.insured_list?.fc}
                  touched={insuredTouched?.fc}
                  value={
                    insuredValue?.fc?.toUpperCase() === undefined
                      ? ''
                      : insuredValue?.fc?.toUpperCase()
                  }
                  error={insuredError?.fc}
                  label={`${t('data_entry_step_2.insured_fc')}*`}
                  placeholder={t('data_entry_step_2.insured_fc_placeholder')}
                  classesField="text-upp-placeholder-low d-flex min-input-height"
                />
              </div>
            </div>
          )}

          {values.insured_list?.[index]?.fc_question === 'no_id' && (
            <div>
              <div className="d-block d-md-flex">
                <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                  <InputDate
                    name={`insured_list.${index}.birth`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validationSchema={validationSchema?.fields?.insured_list?.innerType}
                    touched={insuredTouched?.birth}
                    value={insuredValue?.birth === undefined ? '' : insuredValue?.birth}
                    error={insuredError?.birth}
                    label={`${t('data_entry_step_2.insured_birth')}*`}
                    placeholder={t('data_entry_step_2.insured_birth_placeholder')}
                    disabledTo={new Date()}
                    classes="m-0 p-0"
                  />
                </div>
                <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                  <InputText
                    name={`insured_list.${index}.birth_nation`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    validationSchema={validationSchema?.fields?.insured_list?.birth_nation}
                    touched={insuredTouched?.birth_nation}
                    value={
                      insuredValue?.birth_nation === undefined ? '' : insuredValue?.birth_nation
                    }
                    error={insuredError?.birth_nation}
                    label={`${t('data_entry_step_2.insured_birth_nation')}*`}
                    placeholder={t('data_entry_step_2.insured_birth_nation_placeholder')}
                  />
                </div>
              </div>
              <div className="d-block d-md-flex">
                <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                  <InputText
                    name={`insured_list.${index}.birth_place`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    validationSchema={validationSchema?.fields?.insured_list?.birth_place}
                    touched={insuredTouched?.birth_place}
                    value={insuredValue?.birth_place === undefined ? '' : insuredValue?.birth_place}
                    error={insuredError?.birth_place}
                    label={`${t('data_entry_step_2.insured_birth_place')}*`}
                    placeholder={t('data_entry_step_2.insured_birth_place_placeholder')}
                  />
                </div>

                <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 pr-md-3">
                  <InputRadio
                    name={`insured_list.${index}.gender`}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    options={genderOptions}
                    validationSchema={validationSchema?.fields?.insured_list?.gender}
                    touched={insuredTouched?.gender}
                    error={insuredError?.gender}
                    value={insuredValue?.gender === undefined ? '' : insuredValue?.gender}
                    label={`${t('data_entry_step_2.insured_gender')}*`}
                    classesLabel="m-0 p-0 font-16 d-flex align-items-center mb-3"
                    classes="m-0 p-0 mb-3"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const OtherInsureds = (
    <div>
      {[...Array(Number(values?.insured_number) - 1).keys()]?.map((_, index) =>
        OtherInsured(index)
      )}
    </div>
  )

  const Insureds = (
    <div className="mt-2">
      <div className="col-sm-12 col-md-6 p-0 px-md-3 pl-md-3 mb-3 font-14-semibold text-uppercase">
        {t('data_entry_step_2.insured_title')}
      </div>
      {FirstInsured}
      {OtherInsureds}
    </div>
  )

  const InsuranceConsents = (
    <div className="mt-3">
      <div className="col-sm-12 p-0 px-md-3 pl-md-3 pr-md-3">
        <InputCheckbox
          name="consent_privacy"
          handleChange={handleChange}
          handleBlur={handleBlur}
          validationSchema={validationSchema}
          touched={touched.consent_privacy}
          value={values.consent_privacy}
          error={errors.consent_privacy && touched.consent_privacy ? errors.consent_privacy : ''}
          label={t('data_entry_step_2.consent_privacy_label')}
          classesLabel="m-0 font-16 d-flex align-items-baseline"
        />
        <div className="d-inline-block">
          <div className="py-md-2 font-15 display-contents">
            {t('data_entry_step_2.consent_privacy_text_1')}
          </div>
          <div className="py-md-2 display-contents c-891124 cursor-pointer">
            <Button
              flavor="transparentPrimary"
              text={t('data_entry_step_2.consent_privacy_link')}
              classes="p-0 display-contents"
              classesText="font-15 d-inline border-underline-link"
              onPress={openInformativePersonalDataLink}
            />
          </div>
          <div className="py-md-2 font-15 display-contents">
            {t('data_entry_step_2.consent_privacy_text_2')}
          </div>
        </div>

        <InputCheckbox
          name="consent_marketing"
          handleChange={handleChange}
          handleBlur={handleBlur}
          validationSchema={validationSchema}
          touched={touched.consent_marketing}
          value={values.consent_marketing}
          error={errors.consent_marketing}
          label={t('data_entry_step_2.consent_marketing_label')}
          classesLabel="m-0 font-16 d-flex align-items-baseline mt-3"
        />

        {isMobile ? (
          <Accordion
            title={t('data_entry_step_3.consent_accordion_label')}
            classesTitle="m-0 p-0 font-16 me-3"
            classes="max-w-fit-content"
            classesHead="m-0 p-0 pb-2 mt-3 d-flex justify-content-between align-items-center justify-content-md-start flex-nowrap"
          >
            <div className="d-inline-block">
              <div className="py-md-2 font-15 display-contents">
                {t('data_entry_step_2.consent_marketing_value')}
              </div>
              <div className="py-md-2 display-contents c-891124 cursor-pointer">
                <Button
                  flavor="transparentPrimary"
                  text={t('data_entry_step_2.consent_privacy_link_2')}
                  classes="p-0 display-contents"
                  classesText="font-15 d-inline border-underline-link"
                  onPress={openInformativePersonalDataLink}
                />
              </div>
              <div className="py-md-2 font-15 display-contents">
                {t('data_entry_step_2.consent_ending')}
              </div>
            </div>
          </Accordion>
        ) : (
          <div className="d-inline-block">
            <div className="py-md-2 font-15 display-contents">
              {t('data_entry_step_2.consent_marketing_value')}
            </div>
            <div className="py-md-2 display-contents c-891124 cursor-pointer">
              <Button
                flavor="transparentPrimary"
                text={t('data_entry_step_2.consent_privacy_link_2')}
                classes="p-0 display-contents"
                classesText="font-15 d-inline border-underline-link"
                onPress={openInformativePersonalDataLink}
              />
            </div>
            <div className="py-md-2 font-15 display-contents">
              {t('data_entry_step_2.consent_ending')}
            </div>
          </div>
        )}

        <div>
          <InputCheckbox
            name="consent_profiling"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.consent_profiling}
            value={values.consent_profiling}
            label={t('data_entry_step_2.consent_profiling_label')}
            classesLabel="m-0 font-16 d-flex align-items-baseline mt-3"
          />

          {isMobile ? (
            <Accordion
              title={t('data_entry_step_3.consent_accordion_label')}
              classesTitle="m-0 p-0 font-16 me-3"
              classes="max-w-fit-content"
              classesHead="m-0 p-0 pb-2 mt-3 d-flex justify-content-between align-items-center justify-content-md-start flex-nowrap"
            >
              <div className="d-inline-block">
                <div className="py-md-2 font-15 display-contents">
                  {t('data_entry_step_2.consent_profiling_value')}
                </div>
                <div className="py-md-2 display-contents c-891124 cursor-pointer">
                  <Button
                    flavor="transparentPrimary"
                    text={t('data_entry_step_2.consent_privacy_link_2')}
                    classes="p-0 display-contents"
                    classesText="font-15 d-inline border-underline-link"
                    onPress={openInformativePersonalDataLink}
                  />
                </div>
                <div className="py-md-2 font-15 display-contents">
                  {t('data_entry_step_2.consent_ending')}
                </div>
              </div>
            </Accordion>
          ) : (
            <div className="d-inline-block">
              <div className="py-md-2 font-15 display-contents">
                {t('data_entry_step_2.consent_profiling_value')}
              </div>
              <div className="py-md-2 display-contents c-891124 cursor-pointer">
                <Button
                  flavor="transparentPrimary"
                  text={t('data_entry_step_2.consent_privacy_link_2')}
                  classes="p-0 display-contents"
                  classesText="font-15 d-inline border-underline-link"
                  onPress={openInformativePersonalDataLink}
                />
              </div>
              <div className="py-md-2 font-15 display-contents">
                {t('data_entry_step_2.consent_ending')}
              </div>
            </div>
          )}
        </div>

        <div>
          <InputCheckbox
            name="consent_communication"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.consent_communication}
            value={values.consent_communication}
            label={t('data_entry_step_2.consent_communication_label')}
            classesLabel="m-0 font-16 d-flex align-items-baseline mt-3"
          />
          {isMobile ? (
            <Accordion
              title={t('data_entry_step_3.consent_accordion_label')}
              classesTitle="m-0 p-0 font-16 me-3"
              classes="max-w-fit-content"
              classesHead="m-0 p-0 pb-2 mt-3 d-flex justify-content-between align-items-center justify-content-md-start flex-nowrap"
            >
              <div className="d-inline-block">
                <div className="py-md-2 font-15 display-contents">
                  {t('data_entry_step_2.consent_communication_value')}
                </div>
                <div className="py-md-2 display-contents c-891124 cursor-pointer">
                  <Button
                    flavor="transparentPrimary"
                    text={t('data_entry_step_2.consent_privacy_link_2')}
                    classes="p-0 display-contents"
                    classesText="font-15 d-inline border-underline-link"
                    onPress={openInformativePersonalDataLink}
                  />
                </div>
                <div className="py-md-2 font-15 display-contents">
                  {t('data_entry_step_2.consent_ending')}
                </div>
              </div>
            </Accordion>
          ) : (
            <div className="d-inline-block">
              <div className="py-md-2 font-15 display-contents">
                {t('data_entry_step_2.consent_communication_value')}
              </div>
              <div className="py-md-2 display-contents c-891124 cursor-pointer">
                <Button
                  flavor="transparentPrimary"
                  text={t('data_entry_step_2.consent_privacy_link_2')}
                  classes="p-0 display-contents"
                  classesText="font-15 d-inline border-underline-link"
                  onPress={openInformativePersonalDataLink}
                />
              </div>
              <div className="py-md-2 font-15 display-contents">
                {t('data_entry_step_2.consent_ending')}
              </div>
            </div>
          )}
        </div>

        <div className="pt-4 font-15-semibold">{t('commons.required_field')}</div>
      </div>
    </div>
  )

  return (
    <>
      <FormikProvider value={formik}>
        <ScrollTopRoot />
        {CoverageData}
        {Insureds}
        {InsuranceConsents}
      </FormikProvider>
    </>
  )
})
