import { selector } from 'recoil'
import { LowerLanguage } from 'src/i18n'
import {
  Agency,
  CookieTemplates,
  Coverages,
  Document,
  Domains,
  evaluate_premium_t,
  FactorLimits,
  Factors,
  Faq,
  Logo,
  Optional,
  Periods,
} from 'src/types'

import { atomProduct, atomSide1, atomSide2 } from './data.atoms'

// Start showcase: remove it when feel like a bomber 🚀🚀🚀
export const selectorArea = selector<number>({
  key: 'area',
  get: ({ get }) => get(atomSide1) * get(atomSide2),
})
// End showcase

export const selectorAgency = selector<Optional<Agency>>({
  key: 'agency',
  get: ({ get }) => get(atomProduct)?.agency,
})

export const selectorLanguages = selector<Optional<LowerLanguage[]>>({
  key: 'languages',
  get: ({ get }) => get(atomProduct)?.configuration?.languages,
})

const replaceDocument = (newDocument: Document, documents: Document[]): Document[] => {
  const filteredDocuments = documents?.filter((document) => document?.id !== newDocument?.id) || []
  filteredDocuments?.push(newDocument)
  return filteredDocuments || []
}

export const selectorDocuments = selector<Optional<Document[]>>({
  key: 'documents',
  get: ({ get }) => {
    let updateDocuments: Document[] = []

    let documents = get(atomProduct)?.configuration?.documents || []
    const agencyDocuments = get(selectorAgency)?.documents || []

    if (agencyDocuments?.length > 0) {
      agencyDocuments?.map((agencyDocument) => {
        documents = replaceDocument(agencyDocument, documents)
      })
    }

    updateDocuments = documents?.sort(
      (document1, document2) => Number(document1?.id) - Number(document2?.id)
    )

    return updateDocuments
  },
})

export const selectorLandingDocuments = selector<Optional<Document[]>>({
  key: 'landingDocuments',
  get: ({ get }) =>
    get(selectorDocuments)?.filter(
      ({ id }) => id === '1' || id === '2' || id === '3' || id === '4'
    ),
})

export const selectorFactors = selector<Optional<Factors>>({
  key: 'factors',
  get: ({ get }) => get(atomProduct)?.configuration?.factors,
})

export const selectorPeriods = selector<Optional<Periods>>({
  key: 'periods',
  get: ({ get }) => get(atomProduct)?.configuration?.periods,
})

export const selectorCoverages = selector<Optional<Coverages>>({
  key: 'coverages',
  get: ({ get }) => get(atomProduct)?.configuration?.coverages,
})

export const selectorDomains = selector<Optional<Domains>>({
  key: 'domains',
  get: ({ get }) => get(atomProduct)?.configuration?.domains,
})

export const selectorCookieTemplats = selector<Optional<CookieTemplates>>({
  key: 'cookieTemplates',
  get: ({ get }) => get(atomProduct)?.configuration?.cookieTemplates,
})

export const selectorFactorLimits = selector<FactorLimits>({
  key: 'seasonal',
  get: ({ get }) => {
    const factor = get(atomProduct)?.configuration?.factors?.['1']
    return {
      max: factor?.max || 1,
      min: factor?.min || 1,
    }
  },
})

export const selectorFaqs = selector<Optional<Faq[]>>({
  key: 'faqs',
  get: ({ get }) => get(atomProduct)?.configuration?.faqs,
})

export const selectorLogos = selector<Optional<Logo[]>>({
  key: 'logos',
  get: ({ get }) => get(atomProduct)?.configuration?.logos,
})

export const selectorEvaluatedPremium = selector<Optional<evaluate_premium_t>>({
  key: 'premium',
  get: () => {
    return {
      premium: {
        quoteValue: '100',
        oldQuoteValue: '50',
      },
    }
  },
})
