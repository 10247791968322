/* eslint-disable complexity */
import { Button, Loader } from 'src/ui/widgets'

import { useDataEntry } from './data-entry.hook'
import { DataEntryStep1 } from './data-entry-step1/data-entry-step1'
import { DataEntryStep2 } from './data-entry-step2/data-entry-step2'
import { DataEntryStep3 } from './data-entry-step3/data-entry-step3'

export const DataEntry = (props: { step: number }) => {
  const {
    t,
    agency,
    daysOptions,
    dailyOptions,
    minMaxContractor,
    quote,
    currentStep,
    validationSchemas,
    formik,
    continueDisabled,
    discountError,
    quoting,
    warning,
    evaluatedPremium,
    setDiscountError,
    triggerFormikCheck,
    evaluatePremium,
    goBack,
  } = useDataEntry(props?.step)

  const { handleSubmit, isSubmitting } = formik

  const Topbar = (
    <Button
      flavor="transparentAccentBack"
      text={t('commons.back')}
      classes="mt-3 px-0 ps-md-5 ms-md-2"
      classesText="text-uppercase font-14-semibold"
      onPress={goBack}
    />
  )

  const Title = (
    <div className="px-md-3 mt-1">
      <div className="mb-2 font-30-semibold">{t('data_entry.title')}</div>
      <div className="mb-3 font-16">
        {t('data_entry.step')} {currentStep + 1} {t('data_entry.of')} 4
      </div>
      <div className="mt-3 mb-3 border-top" />
    </div>
  )

  const Form = (
    <>
      {currentStep === 0 && (
        <DataEntryStep1
          dailyOptions={dailyOptions}
          daysOptions={daysOptions}
          minMaxContractor={minMaxContractor || {}}
          validationSchema={validationSchemas[currentStep]}
          formik={formik}
        />
      )}
      {currentStep === 1 && (
        <DataEntryStep2 validationSchema={validationSchemas[currentStep]} formik={formik} />
      )}
      {currentStep === 2 && (
        <DataEntryStep3
          validationSchema={validationSchemas[currentStep]}
          formik={formik}
          discountError={discountError}
          setDiscountError={setDiscountError}
        />
      )}
    </>
  )

  const values = formik.values

  const Total = currentStep <= 1 && (
    <div className="col-12 px-md-3 pl-md-3 mb-2 pt-4 pt-md-0 mb-md-1">
      <div className={`d-block d-md-flex mb-3`}>
        <div className="col-12 mb-3 mb-md-0">
          <div className="d-md-block">
            {quoting ? (
              <div className="m-auto d-flex justify-content-center mb-3">
                <Loader overlay />
              </div>
            ) : (
              evaluatedPremium?.premium?.quoteValue && (
                <div className="d-flex justify-content-center justify-content-md-end align-items-center">
                  <div className="font-15-semibold text-uppercase">
                    {t('data_entry_step_3.total')}
                  </div>
                  <div className="font-30-semibold ms-3">
                    <div>{evaluatedPremium?.premium?.quoteValue?.replace('.', ',')} €</div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {warning && <div className="font-15 mt-3">{t('data_entry_step_3.choose_season')}</div>}
    </div>
  )

  const SubmitButton = (
    <div className="col-12 px-md-3 pl-md-3 mb-2 pt-4 pt-md-0 mb-md-1">
      <div className="d-md-flex justify-content-center justify-content-md-end">
        {(currentStep === 0 || currentStep === 1) && (
          <>
            {(agency?.isOriginalPayment === undefined || agency?.isOriginalPayment) && (
              <div className="pe-md-4 mb-4 mb-md-0 d-flex d-md-block justify-content-center">
                <Button
                  flavor="accent"
                  classes="ps-5 pe-5 d-flex justify-content-center m-auto  m-md-0 "
                  classesText="font-14-semibold text-uppercase"
                  disabled={
                    !values?.insured_days ||
                    !values?.insured_number ||
                    Number(values?.insured_number) < 0 ||
                    Number(values?.insured_number) > 20 ||
                    !values?.insured_days ||
                    values?.coverage_end_date === '' ||
                    formik.errors.coverage_end_date !== undefined
                  }
                  onPress={evaluatePremium}
                  text={t('data_entry_step_1.evaluate')}
                />
              </div>
            )}
            <Button
              type="submit"
              flavor="accent"
              text={t('commons.continue')}
              classes="ps-5 pe-5 d-flex justify-content-center m-auto m-md-0 "
              classesText="font-14-semibold text-uppercase"
              disabled={continueDisabled}
              onPress={triggerFormikCheck}
            />
          </>
        )}

        {currentStep === 2 && (
          <>
            <div className="font-15 col-md-7 mb-4 mb-md-0 text-center text-md-start">
              {t('data_entry_step_3.click_label')}
            </div>
            <Button
              type="submit"
              flavor="accent"
              text={t('data_entry.payment_label')}
              classes="ps-5 pe-5 d-flex justify-content-center m-auto m-md-0 "
              classesText="font-14-semibold text-uppercase"
              disabled={!quote?.quoteValue || isSubmitting || discountError !== ''}
              onPress={triggerFormikCheck}
            />
          </>
        )}
      </div>
    </div>
  )

  return (
    <div className="mb-5 pb-5 ps-3 pe-3 d-flex justify-content-center container-fluid">
      <div className="col-lg-11">
        <form onSubmit={handleSubmit} className="row justify-content-center m-0 p-0">
          <div className="col-lg-10">
            {Topbar}
            <div className="mx-lg-4 col-sm-12 p-0 ps-lg-3 pe-lg-3 pb-lg-3">
              {Title}
              {Form}
              {Total}
              {SubmitButton}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
