import './faqs.scss'

import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { ItasMinusIcon, ItasPlusIcon, LeftArrowIcon } from 'src/assets'
import { lowerLanguage } from 'src/i18n'
import { Paths } from 'src/navigation'
import { Faq } from 'src/types'
import LandingAccordion from 'src/ui/fragments/landing-accordion/Accordion'
import { Button } from 'src/ui/widgets'

import { useFaqs } from './faqs.hook'

export const Faqs: FC = () => {
  const { t, faqs, navigate } = useFaqs()

  const Title = (
    <h3 className="mb-4 faq-title-size d-flex align-item-center text-uppercase">
      {t('landing.section_8_title')}
    </h3>
  )

  const Subtitle = (
    <div className="mb-4 faq-subtitle-size d-flex align-items-center">
      {t('landing.section_8_subtitle')}
    </div>
  )

  const Faqs = (
    <div>
      {faqs &&
        faqs?.length > 0 &&
        faqs.map((item: Faq) => {
          return (
            <LandingAccordion
              key={item?.id}
              icon_size={24}
              icon_open={ItasMinusIcon}
              icon_close={ItasPlusIcon}
              button_read={false}
              classes="mb-5 pb-3 ps-md-3 pe-md-3"
              classes_icon_open="section8-open-icon"
              separator="faq-separator"
              head={
                <h2 className="m-0 p-0 me-3 mb-2 section8-dynamic-size-faqs-title col-8">
                  {item?.request?.[lowerLanguage]}
                </h2>
              }
            >
              <p className="mb-3 white-space-pre">{item?.answer?.[lowerLanguage]}</p>
            </LandingAccordion>
          )
        })}
    </div>
  )

  return (
    <div className="bg-ffffff">
      <Helmet>
        <title>{t('meta.faq.title')}</title>
        <meta name="description" content={t('meta.faq.description')} />
      </Helmet>
      <Button
        flavor="transparentAccent"
        classes="px-0 ps-3 ps-md-5 ms-md-2"
        classesText="text-uppercase font-14-semibold c-2727ab"
        text={t('commons.back')}
        iconLeft={LeftArrowIcon}
        classesIconLeft="me-2 filter-2727ab"
        onPress={() => {
          navigate(Paths.Landing)
        }}
      />
      <div className="mt-5 d-flex align-items-center justify-content-center text-center">
        {Title}
      </div>
      <div className="mb-md-5 pb-5 d-flex align-items-center justify-content-center text-center">
        {Subtitle}
      </div>
      <div className="ps-4 pe-4 d-md-flex justify-content-center">
        <div className="col-md-7">{Faqs}</div>
      </div>
    </div>
  )
}
