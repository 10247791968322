import { Image } from 'react-bootstrap'
import { FailureIcon } from 'src/assets'
import i18n from 'src/i18n'
import { ModalFailureProps, typedMemo } from 'src/types'
import { Button, Modal } from 'src/ui/widgets'

export const ModalFailure = typedMemo(
  ({
    visible,
    icon = FailureIcon,
    description,
    onClose,
    onCloseHome,
    button = {
      flavor: 'primaryNext',
      text: i18n.t('commons.reload_page'),
      onPress: () => {
        onClose?.()
        window.location.reload()
      },
    },
    buttonHome = false,
  }: ModalFailureProps) => {
    return (
      <Modal visible={visible} onClose={onClose}>
        <div className="d-flex align-items-center justify-content-center p-1">
          <div className="d-inline text-center">
            {icon && <Image src={icon} />}
            {description && <p className="mt-4 font-16 pre-line">{description}</p>}
            {!buttonHome && button?.onPress && (
              <div className="mt-4 mb-2 d-flex align-items-center justify-content-center">
                <Button {...button} />
              </div>
            )}
            {buttonHome && onCloseHome && (
              <div className="mt-4 mb-2 d-flex align-items-center justify-content-center">
                <Button
                  text={i18n.t('commons.back_to_homepage')}
                  flavor="primaryNext"
                  onPress={onCloseHome}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    )
  }
)
