import { useTranslation } from 'react-i18next'
import { CountryData } from 'react-phone-input-2'
import { useRecoilValue } from 'recoil'
import i18n from 'src/i18n'
import { regexAccents, regexTaxCode } from 'src/regex'
import { selectorAgency, selectorFactors, selectorPeriods } from 'src/storage'
import {
  addStartDays,
  checkFiscalCode,
  compareDates,
  formatDate,
  isValidDate,
  replaceDotPhoneFormat,
  reverseDate,
  ystr,
  ystr_required,
  yupBirthDate,
  yupBool,
  yupBoolRequired,
  yupDateRequired,
  yupEmailRequired,
  yupStr,
  yupStrRequired,
} from 'src/utils'
import * as Yup from 'yup'

export const useValidationSchemas = () => {
  const { t } = useTranslation()

  const agency = useRecoilValue(selectorAgency)
  const factors = useRecoilValue(selectorFactors)
  const periods = useRecoilValue(selectorPeriods)

  const minPeriod = periods?.['3']?.min ? periods['3']?.min : 0

  const seasonalEndDate = factors?.['2']?.maxDate || ''

  const step1 = Yup.object().shape({
    insured_daily: ystr.when('insured_days', (item, schema) => {
      return item === 'daily' ? schema.required(i18n.t('commons.required')) : schema
    }),
    insured_number: ystr_required.test(
      'check_validation_insured_number',
      i18n.t('commons.error_is_wrong'),
      (value) => {
        return Number(value) > 0 && Number(value) <= 20
      }
    ),
    insured_days: yupStrRequired,
    coverage_type_radios: yupStrRequired,
    coverage_start_date: yupDateRequired
      ?.test('check_date', t('data_entry_step_2.error_date'), (value) => {
        return (
          compareDates(new Date(reverseDate(value)), new Date()) >= 0 &&
          isValidDate(reverseDate(value))
        )
      })
      .test('check_date', t('data_entry_step_2.error_date_season'), (value) => {
        return (
          compareDates(new Date(reverseDate(value)), new Date(reverseDate(seasonalEndDate))) <= 0 &&
          isValidDate(reverseDate(value))
        )
      }),
    coverage_end_date: yupDateRequired
      ?.test('check_date', t('data_entry_step_2.error_date'), (value, field) => {
        if (field.parent.insured_days === 'id_daily') {
          return (
            compareDates(
              new Date(reverseDate(field.parent.coverage_start_date)),
              new Date(reverseDate(value))
            ) === 0 && isValidDate(reverseDate(value))
          )
        }
        return !(
          compareDates(
            new Date(reverseDate(field.parent.coverage_start_date)),
            new Date(reverseDate(value))
          ) > 0 && isValidDate(reverseDate(value))
        )
      })
      .test('check_date_3', t('data_entry_step_2.error_date_season'), (value) => {
        return (
          compareDates(new Date(reverseDate(value)), new Date(reverseDate(seasonalEndDate))) <= 0 &&
          isValidDate(reverseDate(value))
        )
      })
      .test('check_date_2', t('data_entry_step_2.error_date'), (value) => {
        return (
          compareDates(new Date(reverseDate(value)), new Date()) >= 0 &&
          isValidDate(reverseDate(value))
        )
      })
      .test(
        'check_date_4',
        t('data_entry_step_2.error_date_period_min') + (minPeriod + 1),
        (_, field) => {
          if (field.parent.insured_days === 'id_period') {
            return (
              compareDates(
                new Date(
                  reverseDate(
                    formatDate(
                      addStartDays(reverseDate(field.parent.coverage_start_date), minPeriod)
                    )
                  )
                ),
                new Date(
                  reverseDate(formatDate(new Date(reverseDate(field.parent.coverage_end_date))))
                )
              ) <= 0
            )
          }
          return true
        }
      ),

    convention: agency?.structures && agency?.structures?.length > 0 ? yupStrRequired : yupStr,
  })

  const step2 = Yup.object().shape({
    insured_list: Yup.array().of(
      Yup.object().shape({
        name: yupStrRequired.matches(regexAccents, t('commons.error_is_wrong')),
        surname: yupStrRequired.matches(regexAccents, t('commons.error_is_wrong')),
        nationality: yupStr,
        fc_question: yupStrRequired,
        birth: yupBirthDate
          .when('fc_question', (item, schema) => {
            return item === 'no_id' ? schema.required(t('commons.required')) : schema
          })
          .test('check_date', t('data_entry_step_2.error_date'), (value) => {
            return compareDates(new Date(reverseDate(value)), new Date()) <= 0
          })
          .test('check_validation', t('data_entry_step_2.error_date'), (value, field) => {
            return field.schema.exclusiveTests.required === true
              ? isValidDate(reverseDate(value))
              : true
          }),
        birth_nation: yupStr
          .when('fc_question', (item, schema) => {
            return item === 'no_id' ? schema.required(t('commons.required')) : schema
          })
          .matches(regexAccents, t('commons.error_is_wrong')),
        birth_place: yupStr
          .when('fc_question', (item, schema) => {
            return item === 'no_id' ? schema.required(t('commons.required')) : schema
          })
          .matches(regexAccents, t('commons.error_is_wrong')),
        gender: yupStr.when('fc_question', (item, schema) => {
          return item === 'no_id' ? schema.required(t('commons.required')) : schema
        }),
        fc: yupStr
          .when('fc_question', (item, schema) => {
            return item === 'yes_id' ? schema.required(t('commons.required')) : schema
          })
          .matches(regexTaxCode, t('commons.error_is_wrong'))
          .test('check_validation', t('commons.fiscal_code_mismatch'), (value, field) => {
            if (field?.parent?.fc_question === 'yes_id') {
              return (
                checkFiscalCode(field?.parent?.name, field?.parent?.surname, value || '') || false
              )
            }
            return true
          }),
      })
    ),
    coverage_start_date: yupDateRequired
      ?.test('check_date', t('data_entry_step_2.error_date'), (value) => {
        return (
          compareDates(new Date(reverseDate(value)), new Date()) >= 0 &&
          isValidDate(reverseDate(value))
        )
      })
      .test('check_date', t('data_entry_step_2.error_date_season'), (value) => {
        return (
          compareDates(new Date(reverseDate(value)), new Date(reverseDate(seasonalEndDate))) <= 0 &&
          isValidDate(reverseDate(value))
        )
      }),
    coverage_end_date: yupDateRequired
      ?.test('check_date', t('data_entry_step_2.error_date'), (value, field) => {
        if (field.parent.insured_days === 'id_daily') {
          return (
            compareDates(
              new Date(reverseDate(field.parent.coverage_start_date)),
              new Date(reverseDate(value))
            ) === 0 && isValidDate(reverseDate(value))
          )
        }
        return !(
          compareDates(
            new Date(reverseDate(field.parent.coverage_start_date)),
            new Date(reverseDate(value))
          ) > 0 && isValidDate(reverseDate(value))
        )
      })
      .test('check_date_3', t('data_entry_step_2.error_date_season'), (value) => {
        return (
          compareDates(new Date(reverseDate(value)), new Date(reverseDate(seasonalEndDate))) <= 0 &&
          isValidDate(reverseDate(value))
        )
      })
      .test('check_date_2', t('data_entry_step_2.error_date'), (value) => {
        return (
          compareDates(new Date(reverseDate(value)), new Date()) >= 0 &&
          isValidDate(reverseDate(value))
        )
      })
      .test(
        'check_date_4',
        t('data_entry_step_2.error_date_period_min') + (minPeriod + 1),
        (_, field) => {
          if (field.parent.insured_days === 'id_period') {
            return (
              compareDates(
                new Date(
                  reverseDate(
                    formatDate(
                      addStartDays(reverseDate(field.parent.coverage_start_date), minPeriod)
                    )
                  )
                ),
                new Date(
                  reverseDate(formatDate(new Date(reverseDate(field.parent.coverage_end_date))))
                )
              ) <= 0
            )
          }
          return true
        }
      ),
    insured_name: yupStrRequired.matches(regexAccents, t('commons.error_is_wrong')),
    insured_surname: yupStrRequired.matches(regexAccents, t('commons.error_is_wrong')),
    insured_email: yupEmailRequired,
    insured_email_confirm: yupEmailRequired.test(
      'check_email',
      t('data_entry_step_2.error_equal_email'),
      (value, field) => {
        return field.parent.insured_email?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()
      }
    ),
    insured_phone: Yup.object().shape({
      formattedPhone: yupStr.test(
        'validate-insured-phone',
        t('commons.error_is_wrong'),
        function () {
          const { country, value } = this.parent

          const formattedValue = value?.replace(/\D/g, '')

          if (
            formattedValue?.length > 0 &&
            formattedValue !== (country as CountryData)?.dialCode &&
            replaceDotPhoneFormat((country as CountryData)?.format, formattedValue).includes('X') &&
            formattedValue?.length < (country as CountryData)?.dialCode.length + 9
          ) {
            return false
          }

          return true
        }
      ),
    }),
    insured_cap: yupStrRequired.test(
      'check_validation_cap',
      t('commons.error_is_wrong'),
      (value) => {
        return value && value?.length <= 10 ? true : false
      }
    ),
    insured_nationality: yupStr,
    insured_fc_question: yupStrRequired,
    insured_birth: yupBirthDate
      .when('insured_fc_question', (item, schema) => {
        return item === 'no_id' ? schema.required(t('commons.required')) : schema
      })
      .test('check_date', t('data_entry_step_2.error_date'), (value) => {
        return compareDates(new Date(reverseDate(value)), new Date()) <= 0
      })
      .test('check_validation', t('data_entry_step_2.error_date'), (value, field) => {
        return field.schema.exclusiveTests.required === true
          ? isValidDate(reverseDate(value))
          : true
      }),
    insured_birth_nation: yupStr
      .when('insured_fc_question', (item, schema) => {
        return item === 'no_id' ? schema.required(t('commons.required')) : schema
      })
      .matches(regexAccents, t('commons.error_is_wrong')),
    insured_birth_place: yupStr
      .when('insured_fc_question', (item, schema) => {
        return item === 'no_id' ? schema.required(t('commons.required')) : schema
      })
      .matches(regexAccents, t('commons.error_is_wrong')),
    insured_gender: yupStr.when('insured_fc_question', (item, schema) => {
      return item === 'no_id' ? schema.required(t('commons.required')) : schema
    }),
    insured_fc: yupStr
      .when('insured_fc_question', (item, schema) => {
        return item === 'yes_id' ? schema.required(t('commons.required')) : schema
      })
      .matches(regexTaxCode, t('commons.error_is_wrong'))
      .test('check_validation', t('commons.fiscal_code_mismatch'), (value, field) => {
        if (field?.parent?.insured_fc_question === 'yes_id') {
          return (
            checkFiscalCode(
              field?.parent?.insured_name,
              field?.parent?.insured_surname,
              value || ''
            ) || false
          )
        }
        return true
      }),
    consent_privacy: yupBool
      .required(t('commons.consent_required'))
      .oneOf([true], t('commons.consent_required')),
    consent_marketing: yupBool,
    consent_profiling: yupBool,
    consent_communication: yupBool,
  })

  const step3 = Yup.object().shape({
    informative_vision: yupBoolRequired,
    consent_td: yupBool
      .required(t('commons.consent_required'))
      .oneOf([true], t('commons.consent_required')),
  })

  return [step1, step2, step3]
}
