import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { Formik, FormikMainInsured } from 'src/types'

export const useGuarantees = ({ setFieldValue }: Formik<FormikMainInsured>) => {
  const urlSearchParams = new URLSearchParams(useLocation().search)
  const base = urlSearchParams.get('base') === 'true'
  // const seasonal = urlSearchParams.get('seasonal') === 'true'

  useEffect(() => {
    setFieldValue('coverage_type_radios', base ? '1' : '2')
  }, [base])

  // useEffect(() => {
  //   setFieldValue('insured_days', seasonal ? 'id_seasonal' : 'id_daily')
  // }, [seasonal])
}
