import moment from 'moment'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CountryData } from 'react-phone-input-2'
import { useRecoilValue } from 'recoil'
import { lowerLanguage } from 'src/i18n'
import { selectorCoverages, selectorDocuments, selectorFactors, selectorPeriods } from 'src/storage'
import { Callbacks, Formik, FormikMainInsured, InputRadioOption } from 'src/types'
import {
  addStartDays,
  formatDate,
  getCurrentDate,
  getCurrentDateHour,
  getCurrentMinute,
  reverseDate,
  useBoolean,
} from 'src/utils'

export const useDataEntryStep2 = (formik: Formik<FormikMainInsured>) => {
  const { t } = useTranslation()

  const {
    values: { coverage_start_date, insured_days },
    setFieldValue,
  } = formik
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const nationalityOptions = [
    { id: 'IT', value: t('data_entry_step_2.insured_nationality_italy') },
    { id: 'EXT', value: t('data_entry_step_2.insured_nationality_foreign') },
  ]

  const fcQuestionOptions = [
    { id: 'yes_id', value: t('data_entry_step_2.insured_fc_question_yes') },
    { id: 'no_id', value: t('data_entry_step_2.insured_fc_question_no') },
  ]

  const genderOptions = [
    { id: 'M', value: t('data_entry_step_2.insured_gender_male') },
    { id: 'F', value: t('data_entry_step_2.insured_gender_female') },
  ]

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const documents = useRecoilValue(selectorDocuments)

  const informativePersonalDataLink = documents?.find(({ id }) => id === '6')

  const openInformativePersonalDataLink = () => {
    const file = informativePersonalDataLink?.file?.[lowerLanguage]
    if (file) {
      window.open(file.replaceAll('"', ''), '_blank')
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const factors = useRecoilValue(selectorFactors)
  const periods = useRecoilValue(selectorPeriods)
  const coverages = useRecoilValue(selectorCoverages)

  const [closePickerTrigger, , , toggleClosePickerTrigger] = useBoolean()

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const seasonalEndDate = new Date(reverseDate(factors?.['2']?.maxDate || '', '/', '-'))

  const MIN_DATE_PERIOD = periods?.['3']?.min ? periods?.['3']?.min : 0

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const coveragesOptions: InputRadioOption[] = Object.entries(coverages || {}).map(
    ([key, value]) => ({
      id: key,
      value: value?.multiDaysName?.[lowerLanguage],
    })
  )

  const periodOptions = [
    { id: 'id_daily', value: t('data_entry_step_1.coverage_daily') },
    { id: 'id_period', value: t('data_entry_step_1.coverage_period') },
    { id: 'id_seasonal', value: t('data_entry_step_1.coverage_seasonal') },
  ]

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const coverageStartDate = reverseDate(coverage_start_date)
  const coverageStartDatePeriod = formatDate(addStartDays(coverageStartDate, MIN_DATE_PERIOD))

  const coverageStartDateDisabledFrom = new Date()
  const coverageStartDateDisabledTo = seasonalEndDate
  const coverageEndDateDisabledFrom = new Date(reverseDate(coverageStartDatePeriod, '/', '-'))
  const coverageEndDateDisabledTo = seasonalEndDate
  const coverageEndDate = moment(seasonalEndDate).format('DD/MM/YYYY')

  const [isMobile, setIsMobile] = useState(window.innerWidth < 810)

  const [currentDate, setCurrentDate] = useState(false)

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== isMobile) setIsMobile(ismobile)
      },
      false
    )
  }, [isMobile])

  useEffect(() => {
    if (insured_days) {
      setFieldValue('coverage_start_date', moment().format('DD/MM/YYYY'))

      if (
        (insured_days === 'id_period' && formik.values.coverage_end_date === coverage_start_date) ||
        formik.values.coverage_end_date === coverageEndDate
      ) {
        setFieldValue('coverage_end_date', '')
      }

      toggleClosePickerTrigger()
    }
  }, [insured_days])

  useEffect(() => {
    if (coverage_start_date) {
      const callbacks: Callbacks = {
        id_daily: () => {
          setFieldValue('coverage_start_date', coverage_start_date)
          setFieldValue(
            'coverage_end_date',
            formatDate(addStartDays(reverseDate(coverage_start_date), 0))
          )
        },
        id_seasonal: () => setFieldValue('coverage_end_date', formatDate(seasonalEndDate)),
        id_period: () => {
          setFieldValue('coverage_start_date', coverage_start_date)
        },
      }
      callbacks[insured_days]?.()

      const currentDate = getCurrentDate()

      if (
        coverage_start_date &&
        currentDate &&
        insured_days === 'id_daily' &&
        (getCurrentDateHour() > 15 || (getCurrentDateHour() === 15 && getCurrentMinute() >= 30))
      ) {
        setCurrentDate(coverage_start_date === currentDate)
      } else {
        setCurrentDate(false)
      }
    }
  }, [coverage_start_date, insured_days])

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const onChangePhone = (
    value: string,
    data: CountryData,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    setFieldValue('insured_phone', {
      country: data,
      formattedPhone: value === (data as CountryData)?.dialCode ? value : formattedValue,
      value: formattedValue,
    })
  }

  return {
    t,
    lowerLanguage,
    isMobile,
    nationalityOptions,
    fcQuestionOptions,
    genderOptions,
    coveragesOptions,
    periodOptions,
    closePickerTrigger,
    coverageStartDateDisabledFrom,
    coverageStartDateDisabledTo,
    coverageEndDateDisabledFrom,
    coverageEndDateDisabledTo,
    currentDate,
    openInformativePersonalDataLink,
    onChangePhone,
  }
}
