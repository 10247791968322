import i18n from 'src/i18n'

export const get_error = (item?: any, key?: string) => {
  let error_ = ''
  if (key) {
    error_ = item?.[key]
  } else {
    error_ = item
  }
  return error_
}

export const get_lowerCase_language = (): 'it' | 'de' | 'en' => {
  const language = i18n.language.slice(0, 2).toUpperCase()
  return language === 'DE' ? 'de' : language === 'EN' ? 'en' : 'it'
}

export const replaceDotPhoneFormat = (formatterPath: string, formattedNumber: string) => {
  let result = ''
  let dotIndex = 0

  for (const char of formatterPath) {
    if (char === '.') {
      if (dotIndex < formattedNumber.length) {
        result += formattedNumber[dotIndex]
        dotIndex += 1
      } else {
        result += 'X'
      }
    } else {
      result += char
    }
  }

  console.log('result', result)

  return result
}
