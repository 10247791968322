/* eslint-disable complexity */

import { lowerLanguage } from 'src/i18n'
import { ScrollTopRoot } from 'src/roots'
import { DataEntryStepProps, typedMemo } from 'src/types'
import { Button, InputCheckbox, InputText, Loader, Recaptcha } from 'src/ui/widgets'

import { useDataEntryStep3 } from './data-entry-step3.hook'

export const DataEntryStep3 = typedMemo((props: DataEntryStepProps) => {
  const {
    validationSchema,
    formik: { values, errors, touched, handleChange, handleBlur },
  } = props

  const {
    t,
    discountError,
    quoting,
    quote,
    agency,
    oldQuote,
    newQuote,
    price,
    coverages,
    coverage,
    policyCode,
    warning,
    isMultiDays,
    updateTotalQuote,
  } = useDataEntryStep3(props)

  const CoverageTypeBaseExtra = (
    <>
      {coverages?.['1']?.extraTypes &&
        coverages?.['1']?.extraTypes?.map((item, index: number) => (
          <div key={index + 1} className="font-14">
            - {item?.[lowerLanguage]}
          </div>
        ))}
    </>
  )

  const CoverageTypeBase = (
    <>
      {coverages?.['1']?.types &&
        coverages?.['1']?.types?.map((item, index: number) => (
          <div key={index + 1} className="font-14">
            - {item?.[lowerLanguage]}
          </div>
        ))}
    </>
  )

  const CoverageTypePlus = (
    <>
      {coverages?.['2']?.types &&
        coverages?.['2']?.types?.map((item, index: number) => (
          <div key={index + 1} className="font-14">
            - {item?.[lowerLanguage]}
          </div>
        ))}
    </>
  )

  const MainInsured = (
    <div>
      <div className="d-block d-md-flex mb-3">
        <div className="col-12 col-md-7 mb-3 mb-md-0">
          <div className="font-12-semibold text-uppercase c-7f7f7f">
            {t('data_entry_step_3.coverage_start_date')}
          </div>
          <div className="font-14">{values.coverage_start_date}</div>
        </div>
        <div className="col-12 col-md-5">
          <div className="font-12-semibold text-uppercase c-7f7f7f">
            {t('data_entry_step_3.coverage_end_date')}
          </div>
          <div className="font-14">{values.coverage_end_date}</div>
        </div>
      </div>

      {coverage && (
        <div className="d-block d-md-flex mb-3">
          <div className="col-12 col-md-7 mb-3 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.coverage_type_title')}
            </div>
            <div className="font-14">
              {isMultiDays
                ? coverage?.multiDaysName?.[lowerLanguage] || ''
                : coverage?.name?.[lowerLanguage] || ''}
            </div>
          </div>
        </div>
      )}

      <div className="d-block d-md-flex mb-3 mt-3">
        <div className="col-12 mb-md-0">
          <div className="font-12-semibold text-uppercase c-7f7f7f">
            {t('data_entry_step_3.insurance_title')}
          </div>
          {CoverageTypeBase}
          {values.coverage_type_radios === '2' && CoverageTypePlus}
          {isMultiDays && CoverageTypeBaseExtra}
        </div>
      </div>

      <div className="d-block d-md-flex">
        <div className="col-12 col-md-7 mb-3 mb-md-0">
          <div className="font-12-semibold text-uppercase c-7f7f7f">
            {t('data_entry_step_3.email')}
          </div>
          <div className="font-14">{values.insured_email?.toLowerCase()}</div>
        </div>
      </div>
    </div>
  )

  const OtherInsured = (index: number) => (
    <div key={index}>
      <div className="font-14-semibold text-uppercase mb-2">
        {t('data_entry_step_3.insured_title')} {index + 2}
      </div>

      <div className="d-block d-md-flex mb-3">
        {values.insured_list?.[index]?.name && (
          <div className="col-12 col-md-7 mb-3 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.insured_name')}
            </div>
            <div className="font-14">{values.insured_list?.[index]?.name}</div>
          </div>
        )}
        {values.insured_list?.[index]?.surname && (
          <div className="col-5">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.insured_surname')}
            </div>
            <div className="font-14">{values.insured_list?.[index]?.surname}</div>
          </div>
        )}
      </div>

      {values.insured_list?.[index]?.fc_question === 'yes_id' && (
        <div className="d-block d-md-flex mb-3">
          {values.insured_list?.[index]?.fc && (
            <div className="col-12 col-md-7 mb-4 mb-md-0">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t('data_entry_step_3.insured_fc')}
              </div>
              <div className="font-14">{values.insured_list?.[index]?.fc?.toUpperCase()}</div>
            </div>
          )}
        </div>
      )}

      {values.insured_list?.[index]?.fc_question === 'no_id' && (
        <div>
          <div className="d-block d-md-flex mb-3">
            {values.insured_list?.[index]?.birth && (
              <div className="col-12 col-md-7 mb-4 mb-md-0">
                <div className="font-12-semibold text-uppercase c-7f7f7f">
                  {t('data_entry_step_3.insured_birth')}
                </div>
                <div className="font-14">{values.insured_list?.[index]?.birth}</div>
              </div>
            )}
            {values.insured_list?.[index]?.birth_nation && (
              <div className="col-5">
                <div className="font-12-semibold text-uppercase c-7f7f7f">
                  {t('data_entry_step_3.insured_birth_nation')}
                </div>
                <div className="font-14">{values.insured_list?.[index]?.birth_nation}</div>
              </div>
            )}
          </div>
          <div className="d-block d-md-flex mb-3">
            {values.insured_list?.[index]?.birth_place && (
              <div className="col-12 col-md-7 mb-4 mb-md-0">
                <div className="font-12-semibold text-uppercase c-7f7f7f">
                  {t('data_entry_step_3.insured_birth_place')}
                </div>
                <div className="font-14">{values.insured_list?.[index]?.birth_place}</div>
              </div>
            )}

            {values.insured_list?.[index]?.gender && (
              <div className="col-12 col-md-7 mb-4 mb-md-0">
                <div className="font-12-semibold text-uppercase c-7f7f7f">
                  {t('data_entry_step_3.insured_gender')}
                </div>
                <div className="font-14">
                  {values.insured_list?.[index]?.gender === 'M'
                    ? t('data_entry_step_3.insured_gender_male')
                    : values.insured_list?.[index]?.gender === 'F'
                    ? t('data_entry_step_3.insured_gender_female')
                    : ''}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {values.insured_list && index < values.insured_list?.length - 1 && (
        <div className="mt-3 mb-3 border-top" />
      )}
    </div>
  )

  const OtherInsureds = (
    <div className="col-12">
      <div>
        <div className="font-14-semibold text-uppercase mb-2">
          {t('data_entry_step_3.insured_title')} 1
        </div>
        <div className="d-block d-md-flex mb-3">
          <div className="col-12 col-md-7 mb-3 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.insured_name')}
            </div>
            <div className="font-14">{values.insured_name}</div>
          </div>
          <div className="col-5">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.insured_surname')}
            </div>
            <div className="font-14">{values.insured_surname}</div>
          </div>
        </div>
        {values.insured_fc_question === 'no_id' && (
          <div>
            <div className="d-block d-md-flex mb-3">
              {values.insured_birth && (
                <div className="col-12 col-md-7 mb-4 mb-md-0">
                  <div className="font-12-semibold text-uppercase c-7f7f7f">
                    {t('data_entry_step_3.insured_birth')}
                  </div>
                  <div className="font-14">{values.insured_birth}</div>
                </div>
              )}
              {values.insured_birth_nation && (
                <div className="col-5">
                  <div className="font-12-semibold text-uppercase c-7f7f7f">
                    {t('data_entry_step_3.insured_birth_nation')}
                  </div>
                  <div className="font-14">{values.insured_birth_nation}</div>
                </div>
              )}
            </div>
            <div className="d-block d-md-flex mb-3">
              {values.insured_birth_place && (
                <div className="col-12 col-md-7 mb-4 mb-md-0">
                  <div className="font-12-semibold text-uppercase c-7f7f7f">
                    {t('data_entry_step_3.insured_birth_place')}
                  </div>
                  <div className="font-14">{values.insured_birth_place}</div>
                </div>
              )}

              {values.insured_gender && (
                <div className="col-12 col-md-7 mb-4 mb-md-0">
                  <div className="font-12-semibold text-uppercase c-7f7f7f">
                    {t('data_entry_step_3.insured_gender')}
                  </div>
                  <div className="font-14">
                    {values.insured_gender === 'M'
                      ? t('data_entry_step_3.insured_gender_male')
                      : values.insured_gender === 'F'
                      ? t('data_entry_step_3.insured_gender_female')
                      : ''}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="d-block d-md-flex mb-3">
          <div className="col-12 col-md-7 mb-4 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.insured_phone')}
            </div>
            <div className="font-14">
              {values.insured_phone?.formattedPhone ? values.insured_phone?.formattedPhone : '-'}
            </div>
          </div>

          {values.insured_fc && values.insured_fc_question === 'yes_id' && (
            <div className="col-12 col-md-7 mb-4 mb-md-0">
              <div className="font-12-semibold text-uppercase c-7f7f7f">
                {t('data_entry_step_3.insured_fc')}
              </div>
              <div className="font-14">{values.insured_fc?.toUpperCase()}</div>
            </div>
          )}
        </div>
        <div className="d-block d-md-flex mb-3">
          <div className="col-12 col-md-7 mb-4 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.insured_cap')}
            </div>
            <div className="font-14">{values.insured_cap ? values.insured_cap : '-'}</div>
          </div>
        </div>
      </div>

      {values.insured_list && values.insured_list?.length !== 0 && (
        <div className="mt-3 mb-3 border-top" />
      )}

      {values.insured_list?.map((_, index) => OtherInsured(index))}
    </div>
  )

  const InfoCompany = (
    <div>
      <div className="d-block d-md-flex mb-3">
        <div className="col-12 col-md-7 mb-3 mb-md-0">
          <div className="font-12-semibold text-uppercase c-7f7f7f">
            {t('data_entry_step_3.product_label')}
          </div>
          <div className="font-14">{t('data_entry_step_3.product_value')}</div>
        </div>
        <div className="col-12 col-md-5">
          <div className="font-12-semibold text-uppercase c-7f7f7f">
            {t('data_entry_step_3.company_title')}
          </div>
          <div className="font-14">{t('data_entry_step_3.company')}</div>
        </div>
      </div>
      <div className="d-block d-md-flex mb-3">
        {policyCode && (
          <div className="col-12 col-md-7 mb-3 mb-md-0">
            <div className="font-12-semibold text-uppercase c-7f7f7f">
              {t('data_entry_step_3.policy_label')}
            </div>
            <div className="font-14">{policyCode || ''}</div>
          </div>
        )}
        <div className="col-12 col-md-5">
          <div className="font-12-semibold text-uppercase c-7f7f7f">
            {t('data_entry_step_3.contractor_label')}
          </div>
          <div className="font-14">
            {agency?.denomination ? agency?.denomination : t('data_entry_step_3.contractor_value')}
          </div>
        </div>
      </div>
    </div>
  )

  const Conditions = (
    <div>
      <div className="mb-3">
        <InputCheckbox
          name="informative_vision"
          handleChange={handleChange}
          handleBlur={handleBlur}
          validationSchema={validationSchema}
          touched={touched.informative_vision}
          value={values.informative_vision}
          error={errors.informative_vision}
          label={t('data_entry_step_3.informative_vision')}
          classesLabel="m-0 font-16 d-flex align-items-baseline"
        />
      </div>

      <InputCheckbox
        name="consent_td"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched.consent_td}
        value={values.consent_td}
        error={errors.consent_td}
        label={t('data_entry_step_3.consent_accordion_value')}
        classesLabel="m-0 font-16 d-flex align-items-baseline"
      />

      <div className="pt-4 font-15-semibold">{t('commons.required_field')}</div>
    </div>
  )

  const DiscountField = (
    <div>
      <div className="d-block d-md-flex align-items-center justify-content-between">
        <div className="col-md-7 pe-md-5">
          <InputText
            name="discount"
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched.discount}
            value={values.discount?.toUpperCase()}
            error={errors.discount}
            label={t('data_entry_step_3.discount_label')}
            placeholder={t('data_entry_step_3.discount_placeholder')}
          />
        </div>
        <div className="col-md-5 ps-md-5 mb-4 mb-md-0">
          <Button
            flavor="accent"
            text={t('data_entry_step_3.discount_apply_btn')}
            classes="d-flex justify-content-center w-100"
            type="button"
            classesText="font-14-semibold text-uppercase"
            disabled={!values.discount || (!!errors.discount && !!touched.discount)}
            onPress={updateTotalQuote}
          />
        </div>
      </div>
      {values.discount && discountError && <div className="font-14 c-862633">{discountError}</div>}
    </div>
  )

  const Total = (
    <>
      <div className="d-block d-md-flex mb-3">
        <div className="col-12 mb-3 mb-md-0">
          <div className="d-md-block">
            {quote?.quoteValue && (
              <div className="d-flex justify-content-center justify-content-md-end align-items-center">
                <div className="font-15-semibold text-uppercase">
                  {t('data_entry_step_3.total')}
                </div>
                <div className="font-30-semibold ms-3">
                  {price === 'DISCOUNT' ? (
                    <div className="d-flex align-items-center">
                      <div className="line-over me-3">{`${oldQuote} €`}</div>
                      {`${newQuote} €`}
                    </div>
                  ) : (
                    <div>{price}</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {warning && <div className="font-15 mt-3">{t('data_entry_step_3.choose_season')}</div>}
    </>
  )

  return (
    <div className="mb-1 mb-md-5 pb-1 ps-md-3 pe-md-3">
      <ScrollTopRoot />
      <Recaptcha />
      <div className="border border-1 rounded-3 d-flex justify-content-center container-fluid bg-ffffff pt-4 pb-4">
        <div className="col-12 col-md-12 col-xxl-9">
          <div className="font-28-semibold text-center mb-2">{t('data_entry_step_3.title')}</div>
          <div className="d-flex justify-content-center">
            <div className="col-lg-10 d-block">
              <div className="mb-4">{MainInsured}</div>
              <div className="mt-3 mb-3 border-top" />
              <div className="mb-4">{OtherInsureds}</div>
              <div className="mt-3 mb-3 border-top" />
              <div className="mb-4">{InfoCompany}</div>
              <div className="mt-3 mb-3 border-top" />
              <div>{Conditions}</div>
              <div className="mt-3 mb-3 border-top" />
              {quoting ? (
                <Loader classes="m-auto" />
              ) : (
                <>
                  {values.insured_list?.length === 0 && quote?.showDiscountField && DiscountField}
                  <div className="mb-4">{Total}</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
