import './section1.scss'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Paths } from 'src/navigation'

export const useSection1 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  /**
   * Functions
   */
  const goToDataEntry = () => {
    navigate({
      pathname: Paths.DataEntry1,
      search: `?${new URLSearchParams({
        base: 'true',
        seasonal: 'false',
      }).toString()}`,
    })
  }

  return {
    t,
    goToDataEntry,
  }
}
