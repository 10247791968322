import { Amplify, API, Auth } from 'aws-amplify'
import awsExports from 'src/aws-exports'
import { API_NAME } from 'src/config'
import { useMockend } from 'src/networking/mockend'
import { AwsGateRequest, AwsGateResponse } from 'src/types'

Amplify.configure(awsExports)
Auth.configure(awsExports)

export const useAwsGateRequest = <T, D>(gateId: string) => {
  const { isMocked, getMock } = useMockend()

  const doRequest = async ({
    method = 'get',
    path,
    ...request
  }: AwsGateRequest<T>): Promise<AwsGateResponse<D>> => {
    if (isMocked(gateId)) {
      const { data } = await getMock(gateId)
      console.log('[FROM MOCK]: ', '\nGATE: ', gateId, '\nREQUEST: ', request, '\nRESPONSE: ', data)
      return data as AwsGateResponse<D>
    }
    // console.log('[PARAMETERS]: ', method, API_NAME, path, request)
    const response = await API[method](API_NAME, path || '', request)
    if (response?.errorCode) {
      throw response
    }
    return response
  }

  return { doRequest }
}
