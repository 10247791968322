import { DownloadIcon } from 'src/assets'
import { lowerLanguage } from 'src/i18n'
import { Document } from 'src/types'
import { Button } from 'src/ui/widgets'

import { useSection7 } from './section7.hook'

export const Section7 = () => {
  const { t, documents, openDocument } = useSection7()

  const Title = (
    <h3 className="mb-4 section7-dynamic-size-title d-flex align-item-center text-uppercase">
      {t('landing.section_7_title')}
    </h3>
  )

  const Subtitle = (
    <div className="mb-4 section7-dynamic-size-subtitle d-flex align-items-center white-space-pre-subtitle">
      {t('landing.section_7_subtitle')}
    </div>
  )

  const Document = ({ id, description, file }: Document) => (
    <div key={id}>
      {id && (
        <div>
          <div className="ps-md-3 pe-md-3 d-flex align-items-center justify-content-between">
            <div className="section7-dynamic-size-document-title">
              {description?.[lowerLanguage]}
            </div>

            <Button
              text={t('commons.download')}
              iconRight={DownloadIcon}
              iconSize={18}
              flavor="transparentAccent"
              classes="p-2 pb-0 ps-3 pe-3 pe-md-0 d-flex align-items-center c-2727ab"
              classesText="font-20-ag-400 c-2727ab"
              classesIconRight="c-2727b2 ms-2"
              onPress={() => openDocument(file[lowerLanguage])}
            />
          </div>
          <div className="section7-separator" />
        </div>
      )}
    </div>
  )

  const Documents = (
    <div className="pt-4 pt-md-0">
      <div>{documents?.map(Document)}</div>
    </div>
  )

  return (
    <div className="col-xl-12 d-xl-flex justify-content-center">
      <div className="col-xl-6">
        {Title}
        {Subtitle}
      </div>
      <div className="col-xl-6">{Documents}</div>
    </div>
  )
}
