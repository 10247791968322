import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { DEFAULT_AGENCY_CODE } from 'src/config'
import { NotLandingPaths } from 'src/navigation'
import { useGetProduct } from 'src/networking/terminals'
import { regexAgencyCode } from 'src/regex'
import { atomProduct, selectorAgency, useRootLoaders } from 'src/storage'
import { PrivateRouteProps, typedMemo } from 'src/types'
import { Error404 } from 'src/ui/pages'
import { useBoolean } from 'src/utils'

export const PrivateRoute = typedMemo(({ element }: PrivateRouteProps): JSX.Element => {
  const { pathname } = useLocation()

  const agencyCode = pathname === '/' ? DEFAULT_AGENCY_CODE : pathname.replace('/', '')

  const isNotLanding = NotLandingPaths.includes(pathname)

  const saveProduct = useSetRecoilState(atomProduct)
  const agency = useRecoilValue(selectorAgency)

  const product = useRecoilValue(atomProduct)

  const [enabled, allow] = useBoolean()

  const { someRootPending } = useRootLoaders()

  const { crossGate: getProduct, pending: pendingetProduct } = useGetProduct()

  useEffect(() => {
    if (isNotLanding) {
      // RETURN TO ROOT
      allow()
    } else if (agencyCode?.match(regexAgencyCode)) {
      if (agency?.id?.toUpperCase() === agencyCode?.toUpperCase() && product?.createdAt) {
        // Prevent getProduct if data are stored

        if (product?.createdAt !== process.env.REACT_APP_UPDATE_DATE) {
          getProduct(
            { params: { agencyCode: agencyCode?.toUpperCase() } },
            {
              onSuccess: (product) => {
                saveProduct(product)
                allow()
              },
            }
          )
        }

        allow()
      } else {
        getProduct(
          { params: { agencyCode: agencyCode?.toUpperCase() } },
          {
            onSuccess: (product) => {
              saveProduct(product)
              allow()
            },
          }
        )
      }
    }
  }, [])

  if (someRootPending) {
    return <></>
  }

  return enabled ? element : pendingetProduct ? <></> : <Error404 />
})
